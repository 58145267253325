import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function VisualizadorPDF(props: { pdf: any }) {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        setNumPages(numPages);
    }

    const { pdf } = props;

    return (
        <div style={{ textAlign: 'center', width: "100%" }}>
            <Document
                file={pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
    );
}