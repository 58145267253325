import React, { createContext, useContext, useEffect, useState } from "react";
import diferencas from "../Funcoes/diferencas";
import validarCPF from "../Funcoes/validarCPF";
import validarEmail from "../Funcoes/validarEmail";
import { baseUrl } from "../Servicos/api";
import getContests from "../Servicos/getContests";
import getData from "../Servicos/getData";
import { RespostaGetData } from "../Servicos/getData";
import login, { RespostaLogin } from "../Servicos/login";
import updateData from "../Servicos/updateData";
import tipoDocumentos from "../Valores/tipoDocumentos";

interface Estado {
    dados?: RespostaGetData,
    autenticado: boolean,
    passoAtual: number,
    concursoId?: string,
    concursosDisponiveis: {
        id: string,
        valor: string
    }[],
    cpf?: string,
    chave?: string,
    token?: string,
    alerta?: string,
    carregando: boolean
}

interface TipoDocumento {
    id: string;
    tipo: string;
    titulo: string;
    descricao: string;
    grupo: string;
    multiplo: string;
    verso: string;
    anexos: {
        anexo: string;
        id: string;
        TipoDocumento_id: string;
        posicao: string;
        tipo_impressao: string;
        nome_original: string;
        data_criacao: string;
        parecer?: string | undefined;
    }[]
}

interface ContextoDados {
    estadoDadosServidor: Estado["dados"],
    estado: Estado,
    estadoDocumentos: TipoDocumento[],
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        notificar: (alerta: string) => void;
        autenticar: () => Promise<boolean>;
        carregarDados: () => Promise<boolean>;
        atualizarDados: () => Promise<boolean>;
        validarPassoStepper: (passo: number, docsPreenchidos?: string[] | undefined) => Promise<boolean>;
    }
}

export const contextoDados = createContext({} as ContextoDados);

export const ProvedorDados = ({ children }: { children: React.ReactNode }) => {
    const [estado, definirEstado] = useState({
        dados: {
            servidor: {

            },
            documentosNecessarios: [""]
        },
        autenticado: false,
        passoAtual: 0,
        concursoId: undefined,
        concursosDisponiveis: [] as Estado["concursosDisponiveis"],
        cpf: "",
        chave: "",
        carregando: false
    } as Estado);
    const [estadoDadosServidor, definirEstadoDadosServidor] = useState({} as Estado["dados"]);

    const [estadoDocumentos, definirEstadoDocumentos] = useState<TipoDocumento[]>([]);

    useEffect(() => {
        console.log("Original", estado.dados?.documentos);
        if (!estado.dados) return;
        const documentosServidor = estado.dados.documentos;
        const documentosNecessarios = estado.dados.documentosNecessarios.map(doc => String(doc));
        const documentosTabela = tipoDocumentos;
        definirEstadoDocumentos(
            documentosTabela.filter(
                docTabela => documentosNecessarios.includes(docTabela.id)
            ).map(docTabela => {
                const anexos = documentosServidor.filter(docServidor => docServidor.TipoDocumento_id === docTabela.id);
                return {
                    ...docTabela,
                    anexos: anexos.map(anexo => {
                        return {
                            ...anexo,
                            anexo: baseUrl + "/files/" + anexo.anexo
                        };
                    })
                }
            })
        );
    }, [estado])

    const acoes = {
        definirEstado,
        notificar: (alerta: string) => {
            acoes.definirEstado({ ...estado, alerta });
        },
        autenticar: async () => {
            try {
                const resposta: RespostaLogin = await login({
                    cpf: estado.cpf || "",
                    key: estado.chave || ""
                });
                if (resposta.token) {
                    localStorage.setItem("token", resposta.token);
                    definirEstado({ ...estado, token: resposta.token, autenticado: true });
                    return true;
                }
                throw resposta.message;
            } catch (e) {
                acoes.definirEstado({ ...estado, alerta: e ? String(e) : "Não foi possível efetuar login, verifique seu CPF e Chave de Acesso." });
                return false;
            }
        },
        carregarDados: async () => {
            try {
                const dados = await getData();
                dados.documentos = dados.documentos.map(doc => {
                    doc.id = String(doc.id);
                    doc.TipoDocumento_id = String(doc.TipoDocumento_id);
                    doc.posicao = String(doc.posicao);
                    return doc;
                });
                dados.documentosNecessarios = dados.documentosNecessarios.map(doc => String(doc));
                definirEstadoDadosServidor({
                    ...estadoDadosServidor,
                    ...dados
                });
                definirEstado({
                    ...estado,
                    dados
                });
                return true;
            } catch {
                definirEstado({
                    ...estado,
                    autenticado: false
                });
                return false;
            }
        },
        atualizarDados: async (passoID = -1) => {
            const obj1 = {
                ...estadoDadosServidor?.servidor,
                passo: "",
                dependentes: estadoDadosServidor?.dependentes || [],
                documentos: estadoDadosServidor?.documentos || []
            };
            const obj2 = {
                ...estado.dados?.servidor,
                passo: passoID,
                dependentes: estado.dados?.dependentes || [],
                documentos: estado.dados?.documentos || []
            };
            // Object.keys(obj1).filter(chave=>{
            //     return JSON.stringify(obj1[chave]) !== JSON.stringify(obj2[chave]);
            // })
            const requisicao = diferencas(obj1, obj2) as any;
            if (passoID === 6) {
                requisicao.dependentes = obj2.dependentes;
            }
            const resposta = await updateData(
                requisicao
            );
            if (resposta.status) {
                if (estado.dados) {
                    const obj = {};
                    Object.keys(resposta.campos).forEach(campo => {
                        /* @ts-ignore */
                        return obj[campo] = resposta.campos[campo]
                    });
                    definirEstado({
                        ...estado,
                        dados: {
                            ...estado.dados,
                            ...obj
                        }
                    });
                    definirEstadoDadosServidor({ ...estado.dados });
                }
                return true;
            }
            return false;
        },
        validarPassoStepper: async (passo: number) => {
            // Dados Pessoais (1)
            if (passo === 0) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;

                // Campo nome
                if (!servidor.SRV_Nome || servidor.SRV_Nome.split(' ').length <= 1) {
                    acoes.notificar("Nome preenchido é inválido!");
                    return false;
                }

                // Campo sexo
                if (!servidor.SRV_Sexo) {
                    acoes.notificar("Selecione o Sexo!");
                    return false;
                }

                // Grau de Instrução
                if (!servidor.GrauInstrucao_id) {
                    acoes.notificar("Selecione o Grau de Instrução!");
                    return false;
                }

                // Campo Raça/cor
                if (!servidor.tipocor_id) {
                    acoes.notificar("Selecione Raça/cor!");
                    return false;
                }

                // Estado civil
                if (!servidor.TipoEstadoCivil_id) {
                    acoes.notificar("Selecione o Estado Civil!");
                    return false;
                }

                if (["2", "7"].includes(servidor.TipoEstadoCivil_id) && !servidor.SRV_Nome_Conjuge) {
                    acoes.notificar("Preencha o nome do cônjuge!");
                    return false;
                }

                // Pis/Pasep
                if (!servidor.SRV_Pis_Pasep || servidor.SRV_Pis_Pasep.length != 11) {
                    acoes.notificar("Pis/Pasep deve possuir 11 digitos!");
                    return false;
                }
                acoes.atualizarDados(0);
                return true;
            }
            // Dados de Nascimento (2)
            if (passo === 1) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;

                // UF
                if (!servidor.SRV_UF_Nascimento) {
                    acoes.notificar("Selecione o UF de nascimento");
                    return false;
                }

                // Cidade
                if (!servidor.SRV_Cidade_Nascimento) {
                    acoes.notificar("Cidade preenchida é inválida!");
                    return false;
                }

                // Data
                if (!servidor.SRV_Data_Nascimento || servidor.SRV_Data_Nascimento === "0000-00-00") {
                    acoes.notificar("Selecione a Data de Nascimento!");
                    return false;
                }

                // Nome do Pai
                if (servidor.SRV_Nome_Pai && servidor.SRV_Nome_Pai.split(' ').length <= 1) {
                    acoes.notificar("Nome do pai preenchido é inválido!");
                    return false;
                }

                // Nome da Mãe
                if (!servidor.SRV_Nome_Mae || servidor.SRV_Nome_Mae.split(' ').length <= 1) {
                    acoes.notificar("Nome da mãe preenchido é inválido!");
                    return false
                }
                acoes.atualizarDados(1);
                return true;
            }
            // Documentaçao (3)
            if (passo === 2) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;

                if (!servidor.SRV_RG || !servidor.SRV_RG_Orgao_Expedidor || !servidor.SRV_RG_UF_Emissor || !servidor.SRV_RG_Data_Expedicao || servidor.SRV_RG_Data_Expedicao === "0000-00-00") {
                    acoes.notificar("O RG informado é inválido!");
                    return false;
                }

                if (!servidor.SRV_CP_Numero || (!servidor.SRV_CP_Serie && servidor.SRV_CP_Numero.length === 11) || !servidor.SRV_CP_Data_Emissao || servidor.SRV_CP_Data_Emissao == "0000-00-00" || !servidor.SRV_CP_UF_Emissor) {
                    acoes.notificar("A carteira profissional informada é inválida!");
                    return false;
                }

                if (!servidor.SRV_TE_Numero || !servidor.SRV_TE_Zona || !servidor.SRV_TE_Secao) {
                    acoes.notificar("O título de leitor informado é inválido!");
                    return false;
                }

                if (servidor.SRV_Sexo === "M" && (!servidor.SRV_CR_Numero || !servidor.SRV_CR_Serie || !servidor.SRV_CR_Unidade)) {
                    acoes.notificar("O Certificado de Reservista informado é inválido!");
                    return false;
                }

                const valido = await acoes.atualizarDados(2);
                return valido;
            }
            /* Dados Bancário (4) */
            if (passo === 3) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;

                if (!servidor.Bco_Codigo || !servidor.Bco_Agencia || !servidor.Bco_Conta || !servidor.Bco_Operacao) {
                    acoes.notificar("Conta bancária inválida!");
                    return false;
                }
                const valido = await acoes.atualizarDados(3);
                return valido;
            }
            // Endereço (5)
            if (passo === 4) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;

                if (
                    !servidor.End_CEP ||
                    !servidor.End_UF ||
                    !servidor.End_Cidade ||
                    !servidor.End_Bairro ||
                    !servidor.End_Logradouro ||
                    !servidor.End_Numero
                ) {
                    acoes.notificar("Preencha o endereço corretamente!")
                    return false;
                }
                const valido = await acoes.atualizarDados(4);
                return valido;
            }
            // Contato (6)
            if (passo === 5) {
                const servidor = estado.dados?.servidor;
                if (!servidor) return false;
                if (!servidor.Tel_Numero1 || !(
                    [10, 11].includes(servidor.Tel_Numero1.length)
                )) {
                    acoes.notificar("Celular inválido!");
                    return false;
                }
                if (servidor.Tel_Numero2 ?
                    !([10, 11].includes(servidor.Tel_Numero2.length)) :
                    true
                ) {
                    acoes.notificar("Telefone residencial inválido!");
                    return false;
                }
                if (!servidor.SRV_Email || !validarEmail(servidor.SRV_Email)) {
                    acoes.notificar("E-mail inválido!");
                    return false;
                }
                const valido = await acoes.atualizarDados(5);
                return valido;
            }
            /* Dependentes (7) */
            if (passo === 6) {
                const dependentes = estado.dados?.dependentes;
                if (!dependentes) return false;

                const formValido = dependentes.every((dependente, index) => {
                    if (!dependente.TPD_Nome) {
                        acoes.notificar(`Nome do ${index + 1}° dependente é inválida!`);
                        return false;
                    }
                    if (!dependente.Naturalidade) {
                        acoes.notificar(`Naturalidade do ${index + 1}° dependente é inválida!`);
                        return false;
                    }
                    if (!dependente.TPD_CPF ||
                        !validarCPF(dependente.TPD_CPF)
                    ) {
                        acoes.notificar(`O CPF do ${index + 1}° dependente é inválido`);
                        return false;
                    }
                    if (!dependente.TPD_Data_Nascimento || dependente.TPD_Data_Nascimento == "0000-00-00") {
                        acoes.notificar(`Data de Nascimento do ${index + 1}° dependente inválida!`);
                        return false;
                    }
                    if (!dependente.TipoDependente_id) {
                        acoes.notificar(`Selecione o tipo do ${index + 1}° dependente!`);
                        return false;
                    }
                    return true;
                });
                if (formValido) {
                    return await acoes.atualizarDados(6);
                }
                return false;
            }
            if ([8, 9, 10, 11, 12].includes(passo)) {
                const documentos = estado.dados?.documentos;
                if (!documentos) return false;
                const documentosNecessario = estado.dados?.documentosNecessarios;
                if (!documentosNecessario) return false;

                const grupos: [number, string][] = [
                    [8, "identificacao"],
                    [9, "comprovante"],
                    [10, "certificado"],
                    [11, "certidao"],
                    [12, "outros"],
                ];

                // Encontrar grupo atual de documentos
                const grupoAtual = grupos.find(chave => chave[0] === passo);
                if (!grupoAtual) return false;

                // Tipos de Documentos que estao sendo mostrados na tela
                const tipoDocumentosAtuais = tipoDocumentos.filter(tipo => {
                    return tipo.grupo === grupoAtual[1] && // Grupo atual
                        documentosNecessario.includes(tipo.id) // Necessário para o servidor especifico 
                });

                // Buscar todos os tipos que possuem anexo
                const anexos = documentos.filter(doc => {
                    return ["Analise", "Deferido"].some(parecer => parecer === doc.parecer) &&
                        tipoDocumentosAtuais.some(tdoc => tdoc.id === doc.TipoDocumento_id)
                }).map(doc => doc.TipoDocumento_id);

                // Verificar se foi incluído anexo, exceto para documento "6" que nao é obriatório
                const valido = tipoDocumentosAtuais.filter(doc => doc.id !== "6").every(tipoDoc => anexos.includes(tipoDoc.id))

                // Nova Regra 2:
                // Para cada documento indeferido, deve possuir um substituto

                // Buscar todos os documentos que foram indeferidos
                const anexosIndeferidos = documentos.filter(doc => {
                    return doc.parecer === "Indeferido" &&
                        tipoDocumentosAtuais.some(tdoc => tdoc.id === doc.TipoDocumento_id)
                }).map(doc => doc.TipoDocumento_id);

                // Buscar todos os documentos que estiverem em análise
                const anexosAnalise = documentos.filter(doc => {
                    return doc.parecer === "Analise" &&
                        tipoDocumentosAtuais.some(tdoc => tdoc.id === doc.TipoDocumento_id)
                }).map(doc => doc.TipoDocumento_id);

                // Para cada documento indeferido, necessário ter um em análise do mesmo tipo
                const valido2 = anexosIndeferidos.every(tipoDoc => {
                    return anexosAnalise.filter(anexo => anexo === tipoDoc).length
                        >=
                        anexosIndeferidos.filter(anexo => anexo === tipoDoc).length
                });

                //
                // Fim da Regra 2


                if (!valido) {
                    acoes.notificar("Preencha todos os documentos!");
                    return false;
                }

                if (!valido2) {
                    acoes.notificar("Verifique os documentos indeferidos e envie-os novamente!");
                    return false;
                }

                // const carregando = tipoDocumentosAtuais.some(tipoDoc => anexos.includes(tipoDoc.id));

                // if (carregando) {
                //     acoes.notificar("Aguarde o envio dos documentos!");
                //     return false;
                // }
                return await acoes.atualizarDados(passo);
            }
            return await acoes.atualizarDados(passo);
        }
    };

    useEffect(() => {
        if (estado.autenticado) {
            console.log("Autenticado com sucesso, reabrindo dados...")
            acoes.carregarDados();
        }
    }, [estado.autenticado])

    useEffect(() => {
        getContests().then((contests) => {
            definirEstado(
                {
                    ...estado,
                    concursosDisponiveis: contests.map((contest) => {
                        return {
                            id: contest.id,
                            valor: contest.title,
                        }
                    })
                }
            )
        });
    }, [])

    useEffect(() => {
        if (estado.concursoId) {
            localStorage.setItem("contest", estado.concursoId);
            console.log("Definindo concurso ID!");
        }
    }, [estado.concursoId])

    const valores: ContextoDados = {
        estadoDadosServidor,
        estadoDocumentos,
        estado,
        acoes
    }

    return <contextoDados.Provider value={valores}>
        {children}
    </contextoDados.Provider>
}

export default contextoDados;