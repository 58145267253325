import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { LocalizationProvider } from '@mui/lab';
import { ProvedorDados } from './Contextos/contextoDados';

ReactDOM.render(
  <React.StrictMode>
    <ProvedorDados>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <App />
      </LocalizationProvider>
    </ProvedorDados>
  </React.StrictMode>,
  document.getElementById('root')
);