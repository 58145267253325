import CampoTextoEstado from "../../Componentes/CampoTextoEstado";
import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos";

export const Contato = () => {
    const campos: Campos = [{
        titulo: "Celular",
        chave: "Tel_Numero1",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        topLabel: "Telefones",
        maxLength: 12
    }, {
        titulo: "Residencial",
        chave: "Tel_Numero2",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        maxLength: 12
    }, {
        titulo: "E-mail",
        chave: "SRV_Email",
        tipo: "texto",
        topLabel: "Endereço de E-mail",
        maxLength: 100
    }];
    return <RenderizarCampos campos={campos} />;
}
export default Contato; 