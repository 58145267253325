export default [
    {
        "id": "AC",
        "valor": "Acre (AC)"
    },
    {
        "id": "AL",
        "valor": "Alagoas (AL)"
    },
    {
        "id": "AP",
        "valor": "Amapá (AP)"
    },
    {
        "id": "AM",
        "valor": "Amazonas (AM)"
    },
    {
        "id": "BA",
        "valor": "Bahia (BA)"
    },
    {
        "id": "CE",
        "valor": "Ceará (CE)"
    },
    {
        "id": "DF",
        "valor": "Distrito Federal (DF)"
    },
    {
        "id": "ES",
        "valor": "Espírito Santo (ES)"
    },
    {
        "id": "GO",
        "valor": "Goiás (GO)"
    },
    {
        "id": "MA",
        "valor": "Maranhão (MA)"
    },
    {
        "id": "MT",
        "valor": "Mato Grosso (MT)"
    },
    {
        "id": "MS",
        "valor": "Mato Grosso do Sul (MS)"
    },
    {
        "id": "MG",
        "valor": "Minas Gerais (MG)"
    },
    {
        "id": "PA",
        "valor": "Pará (PA)"
    },
    {
        "id": "PB",
        "valor": "Paraíba (PB)"
    },
    {
        "id": "PR",
        "valor": "Paraná (PR)"
    },
    {
        "id": "PE",
        "valor": "Pernambuco (PE)"
    },
    {
        "id": "PI",
        "valor": "Piauí (PI)"
    },
    {
        "id": "RJ",
        "valor": "Rio de Janeiro (RJ)"
    },
    {
        "id": "RN",
        "valor": "Rio Grande do Norte (RN)"
    },
    {
        "id": "RS",
        "valor": "Rio Grande do Sul (RS)"
    },
    {
        "id": "RO",
        "valor": "Rondônia (RO)"
    },
    {
        "id": "RR",
        "valor": "Roraima (RR)"
    },
    {
        "id": "SC",
        "valor": "Santa Catarina (SC)"
    },
    {
        "id": "SP",
        "valor": "São Paulo (SP)"
    },
    {
        "id": "SE",
        "valor": "Sergipe (SE)"
    },
    {
        "id": "TO",
        "valor": "Tocantins (TO)"
    }
]