export default [
    {
        id: "1",
        valor: "Branca"
    },
    {
        id: "2",
        valor: "Preta"
    },
    {
        id: "3",
        valor: "Parda"
    },
    {
        id: "4",
        valor: "Indígena"
    },
    {
        id: "5",
        valor: "Amarela"
    }
];