import { AttachFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ChangeEvent, useContext, useRef, useState } from "react";
import contextoDados from "../../../Contextos/contextoDados";

import { isoLocal } from "../../../Funcoes/isoLocal";
import DialogoConfirmacao from "./DialogoConfirmacao";

export const BotaoUpload = ({ documentoTipoID }: { documentoTipoID: string }) => {
    
    const inputFilesRef = useRef<HTMLInputElement>(null);
    const [dialogoAberto, definirDialogoAberto] = useState(false);
    const [arquivo, definirArquivo] = useState<File>();

    const { estadoDocumentos } = useContext(contextoDados);
    if (!estadoDocumentos) return <></>;

    const documentoTipo = estadoDocumentos.find(documentoN => documentoN.id === documentoTipoID);


    if (!documentoTipo) {
        return <></>
    }


    const handleChangeAttachBtn = () => {
        inputFilesRef?.current?.click();
    }

    const mudarArquivo = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            definirArquivo(e.target.files[0]);
            definirDialogoAberto(true);
        }
    }

    return <>
        {arquivo && (
            <DialogoConfirmacao arquivo={arquivo} documentoTipoID={documentoTipoID} aberto={dialogoAberto} definirAberto={definirDialogoAberto} />
        )}
        <Button variant="contained" style={{ float: "right" }} onClick={handleChangeAttachBtn}>
            <AttachFile />
        </Button>
        <input ref={inputFilesRef} style={{ display: "none" }} onChange={mudarArquivo} type="file" multiple={false} accept="image/x-png,image/jpeg,application/pdf" /> 
    </>
}

export default BotaoUpload;