import { api } from "./api";

export interface RespostaFinishForm {
    status: boolean,
    anexo: string
}

export const finishForm = async () => {
    const resposta: RespostaFinishForm = await api("/finishForm/", "POST", {});
    return resposta;
}

export default finishForm;