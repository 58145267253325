import { useContext } from "react";
import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos"
import contextoDados from "../../Contextos/contextoDados";
import uf from "../../Valores/uf";

export const Documentacao = () => {
    const { estado } = useContext(contextoDados);
    const campos: Campos = [{
        titulo: "Número",
        chave: "SRV_RG",
        tipo: "texto",
        topLabel: "Carteira de Identidade",
        powerUps: ["caixaAlta"],
        maxLength: 20
    },
    {
        titulo: "UF Emissor",
        chave: "SRV_RG_UF_Emissor",
        tipo: "seletor",
        valores: uf,
    }, {
        titulo: "Orgão",
        chave: "SRV_RG_Orgao_Expedidor",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 10
    }, {
        titulo: "Data de Expedição",
        chave: "SRV_RG_Data_Expedicao",
        tipo: "data"
    },
    {
        titulo: "Número",
        chave: "SRV_CP_Numero",
        tipo: "texto",
        topLabel: "Carteira de Trabalho",
        powerUps: ["caixaAlta", "somenteDigitos"],
        maxLength: 10
    },
    {
        titulo: "UF Emissor",
        chave: "SRV_CP_UF_Emissor",
        tipo: "seletor",
        valores: uf,
    }, {
        titulo: "Série",
        chave: "SRV_CP_Serie",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 10
    }, {
        titulo: "Data de Emissão",
        chave: "SRV_CP_Data_Emissao",
        tipo: "data"
    },
    {
        titulo: "Número",
        chave: "SRV_TE_Numero",
        tipo: "texto",
        topLabel: "Título de Eleitor",
        powerUps: ["caixaAlta", "somenteDigitos"],
        maxLength: 12
    }, {
        titulo: "Zona",
        chave: "SRV_TE_Zona",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        maxLength: 3
    }, {
        titulo: "Seção",
        chave: "SRV_TE_Secao",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        maxLength: 4
    },
    {
        titulo: "Número",
        chave: "SRV_CR_Numero",
        tipo: "texto",
        topLabel: "Certificado de Reservista",
        powerUps: ["caixaAlta", "somenteDigitos"],
        mostrar: estado.dados?.servidor.SRV_Sexo === "M",
        maxLength: 15
    }, {
        titulo: "Série",
        chave: "SRV_CR_Serie",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        mostrar: estado.dados?.servidor.SRV_Sexo === "M",
        maxLength: 15
    }, {
        titulo: "Unidade",
        chave: "SRV_CR_Unidade",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        mostrar: estado.dados?.servidor.SRV_Sexo === "M",
        maxLength: 15
    }];
    return <>
        <RenderizarCampos campos={campos} />
    </>
}

export default Documentacao;