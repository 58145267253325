import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Buffer } from "buffer";
interface Valor {
    id: string,
    valor: string
}

interface Propriedades {
    titulo: string,
    valor: string,
    funcaoAlteradora: (valor: string) => void,
    tipo?: "radio" | "select",
    valores: Valor[]
}

export const CampoSeletor = ({ titulo, valor, funcaoAlteradora, tipo, valores }: Propriedades) => {
    if (!tipo) {
        tipo = "radio";
    }
    if (tipo === "radio") {
        return <FormControl component="fieldset">
            <FormLabel component="legend">{titulo}</FormLabel>
            <RadioGroup
                aria-label={titulo}
                name="controlled-radio-buttons-group"
                value={valor}
                onChange={(e) => {
                    funcaoAlteradora(e.target.value);
                }}
            >
                {valores.map(valor => (<FormControlLabel value={valor.id} control={<Radio />} label={valor.valor} />))}
            </RadioGroup>
        </FormControl>
    }
    if (tipo === "select") {
        return <FormControl fullWidth>
            <InputLabel id={Buffer.from(titulo).toString("base64")} shrink={true}>{titulo}</InputLabel>
            <Select
                value={valor}
                label={titulo}
                input={
                    <OutlinedInput notched label={titulo} fullWidth/>
                }
                onChange={(e) => {
                    funcaoAlteradora(e.target.value || "");
                }}
            >
                {valores.map(valor => (
                    <MenuItem value={valor.id}>{valor.valor}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }
    return <></>
}

export default CampoSeletor;