import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos"
import banco from "../../Valores/banco";

export const DadosBancario = () => {
    const campos: Campos = [{
        titulo: "Banco",
        chave: "Bco_Codigo",
        tipo: "seletor",
        valores: banco,
        topLabel: "Conta bancária"
    },
    {
        titulo: "Agência",
        chave: "Bco_Agencia",
        powerUps: ["caixaAlta", "somenteDigitos"],
        tipo: "texto",
        maxLength: 10
    },
    {
        titulo: "Conta",
        chave: "Bco_Conta",
        powerUps: ["caixaAlta", "somenteDigitos"],
        tipo: "texto",
        maxLength: 15
    },
    {
        titulo: "Operação",
        chave: "Bco_Operacao",
        powerUps: ["caixaAlta", "somenteDigitos"],
        tipo: "texto",
        maxLength: 6
    }];

    return <><RenderizarCampos campos={campos} /></>
}