export default [
    {
        "id": "0",
        "valor": "Ensino Fundamental (Incompleto)"
    },
    {
        "id": "1",
        "valor": "Ensino Fundamental"
    },
    {
        "id": "2",
        "valor": "Ensino Médio (Incompleto)"
    },
    {
        "id": "3",
        "valor": "Ensino Médio"
    },
    {
        "id": "4",
        "valor": "Ensino Médio (Tecnólogo)"
    },
    {
        "id": "5",
        "valor": "Ensino Superior (Incompleto)"
    },
    {
        "id": "6",
        "valor": "Ensino Superior"
    },
    {
        "id": "7",
        "valor": "Pós-Graduação (Especialização)"
    },
    {
        "id": "8",
        "valor": "Pós-Graduação (Mestrado)"
    },
    {
        "id": "9",
        "valor": "Pós-Graduação (Doutorado)"
    }
];