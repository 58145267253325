import { Typography } from "@mui/material"
import { useContext } from "react";
import contextoDados from "../../../Contextos/contextoDados";

export const Titulo = ({ documentoTipoID }: { documentoTipoID: string }) => {
    const { estadoDocumentos } = useContext(contextoDados);
    if(!estadoDocumentos) return <></>;

    const documentoTipo = estadoDocumentos.find(documentoN => documentoN.id === documentoTipoID);
    if (!documentoTipo) {
        return <></>
    }
    return <>
        <Typography variant="subtitle2">{documentoTipo.titulo}</Typography>
        <Typography variant="body1">{documentoTipo.descricao}</Typography>
    </>

}
export default Titulo