import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import VisualizadorPDF from "../../../Componentes/VisualizadorPDF";
import contextoDados from "../../../Contextos/contextoDados";
import { isoLocal } from "../../../Funcoes/isoLocal";
import { baseUrl } from "../../../Servicos/api";

interface Propriedades {
    arquivo: File,
    aberto: boolean,
    definirAberto: React.Dispatch<React.SetStateAction<boolean>>,
    documentoTipoID: string
}

interface Resposta {
    "status": boolean,
    "message": string,
    "filename": string,
    "id": string,
}

export const DialogoConfirmacao = ({ documentoTipoID, arquivo, aberto, definirAberto }: Propriedades) => {


    const { estado, acoes } = useContext(contextoDados);

    

    const enviarAnexo = () => {

        const url_visualizacao = URL.createObjectURL(arquivo);

        const anexoID = arquivo.name;

        if (!estado.dados) return;

        acoes.definirEstado({
            ...estado,
            dados: {
                ...estado.dados,
                documentos: [
                    ...estado.dados.documentos,
                    {
                        id: anexoID,
                        TipoDocumento_id: documentoTipoID,
                        anexo: arquivo.type.indexOf('pdf') === -1 ? url_visualizacao : "",
                        data_criacao: isoLocal(),
                        posicao: "1",
                        tipo_impressao: "frente",
                        nome_original: arquivo.name,
                        parecer: "Enviando"
                    }
                ]
            }
        });

        const formData = new FormData();
        arquivo && formData.append("arquivo", arquivo);

        formData.append("TipoDocumento_id", documentoTipoID);
        formData.append("posicao", "1");
        formData.append("tipo_impressao", "frente");
        formData.append("nome_original", arquivo.name || "anexo");

        fetch(baseUrl + "/uploadFile/", {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": localStorage.getItem("token") || "",
                "Contest": localStorage.getItem("contest") || "",
            }
        }).then(resposta => {
            return resposta.json()
        }).then((resposta: Resposta) => {
            if (!estado.dados) return false;

            if(!resposta.status) {
                throw "Erro";
            }
            // console.log("use=>", estado.dados.documentos);
            acoes.definirEstado({
                ...estado,
                dados: {
                    ...estado.dados,
                    documentos: [
                        ...estado.dados.documentos,
                        {
                            id: resposta.id,
                            TipoDocumento_id: documentoTipoID,
                            anexo: arquivo.type.indexOf('pdf') === -1 ? url_visualizacao : "",
                            data_criacao: isoLocal(),
                            posicao: "1",
                            tipo_impressao: "frente",
                            nome_original: arquivo.name,
                            parecer: "Analise"
                        }
                    ].filter(doc => doc.id !== anexoID).sort((a, b) => Number(a) - Number(b))
                }
            });
            //acoes.definirEstado();//alterarStatus(anexoID, resposta.id, "Analise");
        }).catch(err=>{
            if (!estado.dados) return false;
            acoes.definirEstado({
                ...estado,
                dados: {
                    ...estado.dados,
                    documentos: [
                        ...estado.dados.documentos,
                        {
                            id: new Date().toString(),
                            TipoDocumento_id: documentoTipoID,
                            anexo: arquivo.type.indexOf('pdf') === -1 ? url_visualizacao : "",
                            data_criacao: isoLocal(),
                            posicao: "1",
                            tipo_impressao: "frente",
                            nome_original: arquivo.name,
                            parecer: "Erro"
                        }
                    ].filter(doc => doc.id !== anexoID).sort((a, b) => Number(a) - Number(b))
                }
            });
        });
    }

    const confirmar = (confirmado: boolean) => {
        definirAberto(false);
        if (confirmado) {
            enviarAnexo();
        }
    };

    

    return <>
        <Dialog fullWidth maxWidth="sm" open={aberto} onClose={() => {
            definirAberto(false);
        }}>
            <DialogTitle>Confirmar Anexo</DialogTitle>
            <DialogContent dividers>
                {arquivo.type.indexOf('image') >= 0 && (
                    <img style={{ width: '100%' }} src={URL.createObjectURL(arquivo)} />
                )}
                {arquivo.type.indexOf('application/pdf') >= 0 && (
                    <VisualizadorPDF pdf={URL.createObjectURL(arquivo)} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    confirmar(false);
                }}>Cancelar</Button>
                <Button onClick={() => {
                    confirmar(true);
                }}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default DialogoConfirmacao;