import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos";

export const Endereco = () => {
    const campos: Campos = [{
        titulo: "CEP",
        chave: "End_CEP",
        tipo: "texto",
        powerUps: ["caixaAlta", "somenteDigitos"],
        topLabel: "Residencial",
        maxLength: 8
        
    }, {
        titulo: "UF",
        chave: "End_UF",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 2
    },
    {
        titulo: "Cidade",
        chave: "End_Cidade",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 60
    },
    {
        titulo: "Bairro",
        chave: "End_Bairro",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 60
    },
    {
        titulo: "Logradouro",
        chave: "End_Logradouro",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 60
    },
    {
        titulo: "N°",
        chave: "End_Numero",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 10
    },
    {
        titulo: "Complemento",
        chave: "End_Complemento",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        maxLength: 60
    }];
    return <RenderizarCampos campos={campos}/>;
}
export default Endereco; 