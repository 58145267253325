export const saudacao = () => {
    //let h = new Date().toLocaleTimeString('pt-BR', { hour: 'numeric', hour12: false }); 
    let h = new Date().getHours();
    switch (true) {
        case h <= 5: return 'Boa madrugada';
        case h < 12: return 'Bom dia';
        case h < 18: return 'Boa tarde';
        default: return 'Boa noite';
    }
}

export default saudacao;