export const baseUrl = "/api";

export const api = async (url: string, method: "GET" | "POST", body: any) => {
    return fetch(`${baseUrl}${url}`, {
        body: JSON.stringify(body),
        method,
        headers: {
            "Authorization": localStorage.getItem("token") || "",
            "Contest": localStorage.getItem("contest") || "",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(async result => {
        const json = await result.json();
        return json;
    })
}