import useCriarFuncaoAlteradora from "../../Funcoes/useCriarFuncaoAlteradora";
import CampoTexto, { powerUp } from "../CampoTexto";

interface Propriedades {
    chave: string,
    titulo: string,
    contexto: "dadosServidor" | "geral",
    tipo?: "texto" | "numerico" | "senha" | "data",
    powerUps?: powerUp[],
    maxLength?: number
}

export const CampoTextoEstado = ({ chave, titulo, contexto, tipo, powerUps, maxLength }: Propriedades) => {
    const [estado, definirEstado] = useCriarFuncaoAlteradora({ chave, contexto });
    return <CampoTexto titulo={titulo} valor={estado || ""} funcaoAlteradora={definirEstado} tipo={tipo} powerUps={powerUps} maxLength={maxLength}/>
    
}

export default CampoTextoEstado;