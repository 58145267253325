import { Button } from "@mui/material";
import { useContext } from "react";
import CampoSeletorEstado from "../../Componentes/CampoSeletorEstado";
import CampoTextoEstado from "../../Componentes/CampoTextoEstado";
import contextoDados from "../../Contextos/contextoDados";
export const Autenticacao = () => {
    const { estado, acoes } = useContext(contextoDados);
    return <>
        <CampoSeletorEstado
            chave="concursoId"
            titulo="Concurso"
            contexto="geral"
            tipo="select"
            valores={estado.concursosDisponiveis}
        />
        <br /><br />
        <CampoTextoEstado
            chave="cpf"
            titulo="CPF"
            contexto="geral"
            powerUps={["caixaAlta", "somenteDigitos"]}
            maxLength={11} />

        <br /><br />
        <CampoTextoEstado
            chave="chave"
            titulo="Chave de Acesso"
            contexto="geral"
            powerUps={["caixaAlta"]}
            maxLength={6} />

        <br /><br />


        <div style={{ textAlign: 'center' }}>
            <Button variant="contained" fullWidth onClick={() => {
                acoes.autenticar();
            }}>Acessar</Button>
        </div>
        <br /><br /><br />
    </>;
}
export default Autenticacao;