import useCriarFuncaoAlteradora from "../../Funcoes/useCriarFuncaoAlteradora";
import CampoSeletor from "../CampoSeletor";

interface Valor {
    id: string,
    valor: string
}

interface Propriedades {
    chave: string,
    titulo: string,
    contexto: "dadosServidor" | "geral",
    tipo?: "radio" | "select"
    valores: Valor[]
}

export const CampoSeletorEstado = ({ chave, titulo, contexto, tipo, valores }: Propriedades) => {
    const [estado, definirEstado] = useCriarFuncaoAlteradora({ chave, contexto });
    return <CampoSeletor titulo={titulo} valor={estado || ""} funcaoAlteradora={definirEstado} tipo={tipo} valores={valores}/>
}

export default CampoSeletorEstado;