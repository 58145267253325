import { FormLabel } from "@mui/material";
import CampoSeletorEstado from "../CampoSeletorEstado";
import { powerUp } from "../CampoTexto";
import CampoTextoEstado from "../CampoTextoEstado";

export type Campos = {
    titulo: string,
    chave: string,
    tipo: "texto" | "radio" | "seletor" | "data",
    valores?: {
        id: string,
        valor: string
    }[],
    topLabel?: string,
    mostrar?: boolean,
    powerUps?: powerUp[],
    maxLength?: number
}[];

export const RenderizarCampos = ({ campos }: { campos: Campos }) => {
    return <>
        {campos.map(campo => {
            if (campo.mostrar === false) {
                return <></>
            }
            return <>
                {campo.topLabel && <><br /><FormLabel>{campo.topLabel}</FormLabel><br /><br /></>}
                {campo.tipo === "texto" && (
                    <CampoTextoEstado powerUps={campo.powerUps} chave={campo.chave} titulo={campo.titulo} contexto="dadosServidor" maxLength={campo.maxLength} />
                )}
                {campo.tipo === "data" && (
                    <CampoTextoEstado chave={campo.chave} titulo={campo.titulo} contexto="dadosServidor" tipo="data" />
                )}
                {campo.tipo === "radio" && (
                    <CampoSeletorEstado chave={campo.chave} titulo={campo.titulo} contexto="dadosServidor" tipo="radio" valores={campo.valores || []} />
                )}
                {campo.tipo === "seletor" && (
                    <CampoSeletorEstado chave={campo.chave} titulo={campo.titulo} contexto="dadosServidor" tipo="select" valores={campo.valores || []} />
                )}
                <br />
                <br />
            </>
        })}
    </>;
}

export default RenderizarCampos;