import { api } from "./api";

type RespostaGetContests = {
    "id": string,
    "title": string
}[];

export const getContests = async () => {
    const resposta: RespostaGetContests = await api("/getContests/", "GET", undefined);
    return resposta;
}

export default getContests;