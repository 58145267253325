import { MobileDatePicker } from "@mui/lab";
import { Autocomplete, Card, TextField } from "@mui/material";
import { useContext, useState } from "react";
import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos";
import contextoDados from "../../Contextos/contextoDados";
import uf from "../../Valores/uf";

export const Nascimento = () => {

    const campos: Campos = [
        {
            titulo: "UF",
            chave: "SRV_UF_Nascimento",
            tipo: "seletor",
            topLabel: "Local",
            valores: uf,
        },
        {
            titulo: "Cidade",
            chave: "SRV_Cidade_Nascimento",
            tipo: "texto",
            powerUps: ["caixaAlta"],
            maxLength: 60
        },
        {
            titulo: "Data de Nascimento",
            chave: "SRV_Data_Nascimento",
            tipo: "data"
        },
        {
            titulo: "Nome do Pai",
            chave: "SRV_Nome_Pai",
            tipo: "texto",
            topLabel: "Filiação",
            powerUps: ["caixaAlta"],
            maxLength: 60
        },
        {
            titulo: "Nome da Mãe",
            chave: "SRV_Nome_Mae",
            tipo: "texto",
            powerUps: ["caixaAlta"],
            maxLength: 60
        }
    ];

    return <RenderizarCampos campos={campos} />;
}

export default Nascimento;