import { Typography } from "@mui/material"
import { useContext } from "react";
import contextoDados from "../../Contextos/contextoDados";

export const Protocolo = () => {
    const cidadeData = useContext(contextoDados).estado.dados?.servidor?.cidadeData;
    // const data = new Date().toLocaleString();
    return <>

        <Typography>
            <br /><br />
            Caríssimo servidor,<br /><br />

            Informamos que seus documentos foram encaminhados nesta data para análise do Setor Pessoal da Secretaria Municipal de Educação de São José.<br />
            Fique atento em seu e-mail para o recebimento da confirmação do seu cadastro e para regularização de divergências em sua documentação.<br />
            <br /><br />
            {cidadeData}
            <br /><br />
            Atenciosamente,
            <br /><br />
            Setor de Pessoal/SME<br />
            Prefeitura Municipal de São José.<br />
        </Typography>
    </>
}