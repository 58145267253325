import { api } from "./api";

export interface RequisicaoDeleteFile {
    "id": string
}

export interface RespostaDeleteFile {
    "status": boolean
}

export const deleteFile = async (requisicao: RequisicaoDeleteFile) => {
    const Resposta: RespostaDeleteFile = await api("/deleteFile/", "POST", requisicao);
    return Resposta;
}

export default deleteFile;