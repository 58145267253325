export const diferencas = (obj1, obj2) => {
    const obj3 = {};
    Object.keys(obj1).filter(chave=>{
        return JSON.stringify(obj1[chave]) !== JSON.stringify(obj2[chave]);
    }).forEach(chave=>{
        obj3[chave] = obj2[chave];
    })
    console.log(obj1, obj2, obj3);
    return obj3;
}

export default diferencas;