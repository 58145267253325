import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import contextoDados from "../../Contextos/contextoDados";
import Anexos from "./Componentes/Anexos";
import BotaoUpload from "./Componentes/BotaoUpload";
import Titulo from "./Componentes/Titulo";

export const Documentos = ({ grupo }: { grupo: string }) => {
    const { estado, acoes, estadoDocumentos } = useContext(contextoDados);

    const estadoDocumentosFiltrado = estadoDocumentos
        .filter(documentoTipo => documentoTipo.grupo === grupo)
        .filter(documentoTipo => {
            return !(documentoTipo.anexos.length > 0 && documentoTipo.anexos.every(anexo => anexo.parecer === 'Deferido'))
        });

    if (!estadoDocumentosFiltrado) return <></>;

    if (estadoDocumentosFiltrado.length === 0) {
        return <Typography>Não há nada para você anexar nesta categoria! Clique em próximo.</Typography>
    }


    return <>{estadoDocumentosFiltrado.filter(documentoTipo => documentoTipo.grupo === grupo).map(documentoTipo => {

        return <React.Fragment key={documentoTipo.id}>
            <br />
            <Divider /> <br />
            <Grid container spacing={2}>
                <Grid item xs={8} md={10}>
                    <Titulo documentoTipoID={documentoTipo.id} />
                </Grid>
                <Grid item xs={4} md={2}>
                    <BotaoUpload documentoTipoID={documentoTipo.id} />
                </Grid>
                <Grid item xs={12}>
                    <Anexos documentoTipoID={documentoTipo.id} />
                </Grid>
            </Grid>
        </React.Fragment>
    })}<Divider /> <br /></>
}

export default Documentos;