import { useContext } from "react";
import RenderizarCampos, { Campos } from "../../Componentes/RenderizarCampos";
import contextoDados from "../../Contextos/contextoDados";
import estadoCivil from "../../Valores/estadoCivil";
import grauInstrucao from "../../Valores/grauInstrucao";
import sexo from "../../Valores/sexo";
import tipocor from "../../Valores/tipocor";

export const DadosPessoais = () => {
    const { estado } = useContext(contextoDados);
    const campos: Campos = [{
        titulo: "Nome",
        chave: "SRV_Nome",
        topLabel: "Servidor",
        tipo: "texto",
        powerUps: ["caixaAlta", "desligado"],
        maxLength: 100
    },
    {
        titulo: "Sexo",
        chave: "SRV_Sexo",
        tipo: "radio",
        valores: sexo
    }, {
        titulo: "Grau de Instrução",
        chave: "GrauInstrucao_id",
        tipo: "seletor",
        valores: grauInstrucao,
        topLabel: "Dados Gerais"
    },
    {
        titulo: "Raça/Cor",
        chave: "tipocor_id",
        tipo: "seletor",
        valores: tipocor,
        topLabel: "Dados Gerais"
    }, {
        titulo: "Estado Civil",
        chave: "TipoEstadoCivil_id",
        tipo: "seletor",
        valores: estadoCivil
    },
    {
        titulo: "Nome do Cônjuge",
        chave: "SRV_Nome_Conjuge",
        tipo: "texto",
        powerUps: ["caixaAlta"],
        mostrar: ["2", "7"].includes(estado.dados?.servidor.TipoEstadoCivil_id || ""),
        maxLength: 60
    },
    {
        titulo: "Pis/Pasep",
        chave: "SRV_Pis_Pasep",
        powerUps: ["caixaAlta", "somenteDigitos"],
        tipo: "texto",
        maxLength: 15
    }];
    return <>
        <RenderizarCampos campos={campos} />
    </>;
}
export default DadosPessoais;