import { AttachFile, Delete } from "@mui/icons-material";
import { Avatar, Card, Grid, IconButton, LinearProgress, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { useContext } from "react";
import contextoDados from "../../../Contextos/contextoDados";
import { tempo } from "../../../Funcoes/tempo";
import { baseUrl } from "../../../Servicos/api";
import deleteFile from "../../../Servicos/deleteFile";

export const Anexos = ({ documentoTipoID }: { documentoTipoID: string }) => {

    const { estadoDocumentos, estado, acoes } = useContext(contextoDados);

    if (!estadoDocumentos) return <></>;

    const documentoTipo = estadoDocumentos.find(documentoN => documentoN.id === documentoTipoID);
    const anexos = estado.dados?.documentos || [];
    if (!documentoTipo) {
        return <></>
    }
    return <>
        <Grid container spacing={2}>
            {anexos.filter(anexo => anexo.TipoDocumento_id === documentoTipoID).map(anexo => {
                const datahora = tempo(new Date(anexo.data_criacao));
                const TextoPrimario = () => {
                    return <>{anexo.nome_original}</>
                };
                const TextoSecundario = () => {
                    switch (anexo.parecer) {
                        case "Enviando": return <>
                            <LinearProgress />
                        </>
                        case "Analise": return <>
                            <span style={{ color: "#2980b9" }}>Em análise</span>
                        </>
                        case "Deferido": return <>
                            <span style={{ color: "#27ae60" }}>Deferido</span>
                        </>
                        case "Indeferido": return <>
                            <span style={{ fontWeight: "bold", color: "#c0392b" }}>Documento indeferido: {anexo.Justificativa}</span>
                        </>
                        case "Erro": return <>
                            <span style={{ fontWeight: "bold", color: "#c0392b" }}>Erro, favor enviar novamente!</span>
                        </>
                        default: return <>{anexo.parecer}</>
                    }
                };

                const deletarArquivoHandle = async (id: string) => {
                    const resposta = await deleteFile({ id });
                    if (resposta && resposta.status && estado.dados) {
                        acoes.definirEstado({
                            ...estado,
                            dados: {
                                ...estado.dados,
                                documentos: estado.dados.documentos.filter(doc => doc.id !== id)
                            }
                        })
                    }
                };

                return <Grid key={anexo.id} item xs={12}>
                    <Card>
                        <ListItem secondaryAction={
                            anexo.parecer === "Analise" && <IconButton color="error" aria-label="Atualizar" onClick={() => {
                                deletarArquivoHandle(anexo.id);
                            }}>
                                <Delete />
                            </IconButton>
                        }>
                            <ListItemAvatar>
                                <Avatar src={anexo.anexo.indexOf("blob:") >= 0 ? anexo.anexo : baseUrl + "/files/" + anexo.anexo} variant="rounded">
                                    <AttachFile />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<TextoPrimario />} secondary={<TextoSecundario />} />
                        </ListItem>
                    </Card>
                </Grid>
            })}

        </Grid>
    </>

}
export default Anexos;