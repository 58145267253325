import { Alert, Card, CardContent, Container, createTheme, Snackbar, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import "./App.css";
import StepperDinamico from "./Componentes/StepperDinamico";
import { useContext } from "react";
import contextoDados from "./Contextos/contextoDados";
import BemVindo from "./Componentes/BemVindo";
import Autenticacao from "./Formularios/Autenticacao";
import saudacao from "./Funcoes/saudacao";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#6b9dd1',
        main: '#386fa0',
        dark: '#004471',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ef5665',
        main: '#b71c3b',
        dark: '#800016',
        contrastText: '#fff',
      },
    },
  });

  const { acoes, estado } = useContext(contextoDados);

  return (
    <ThemeProvider theme={theme}>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={estado.alerta ? true : false}
        onClose={() => {
          acoes.definirEstado({ ...estado, alerta: undefined });
        }}
      >
        <Alert onClose={() => {
          acoes.definirEstado({ ...estado, alerta: undefined });
        }} severity="error" sx={{ width: '100%' }}>
          {estado.alerta}
        </Alert>
      </Snackbar>

      <Container maxWidth="md">
        <img src="bn.jpg" style={{
          width: '100%',
          borderTop: '8px solid rgb(203, 31, 66)',
          marginTop: '0px',
          borderRadius: '7px',
          objectFit: 'cover'
        }} />
        <Card style={{ minHeight: "70vh", marginTop: '10px', background: '#eee', padding: '2vh', borderTop: "8px solid #CB1F42" }}>
          <CardContent>
            {estado.autenticado ? (
              <StepperDinamico />
            ) : (<>
              <Typography variant="body1">
                {saudacao()}! Seja bem vindo ao sistema de cadastramento on-line. <br /><br />
              </Typography>
              <Autenticacao />
              <BemVindo />
            </>)}
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}

export default App;
