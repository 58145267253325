import { api } from "./api";

export interface RequisicaoLogin {
    cpf: string,
    key: string
}

export interface RespostaLogin {
    status: boolean,
    message?: string,
    token: string
}

export const login = async (requisicao: RequisicaoLogin) => {
    const resposta: RespostaLogin = await api("/login/", "POST", requisicao);
    return resposta;
}

export default login;