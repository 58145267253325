export default [{
    "id": "1",
    "valor": "Solteiro(a)"
},
{
    "id": "2",
    "valor": "Casado(a)"
},
{
    "id": "3",
    "valor": "Viúvo(a)"
},
{
    "id": "4",
    "valor": "Desquitado(a)"
},
{
    "id": "5",
    "valor": "Divorciado(a)"
},
{
    "id": "6",
    "valor": "Separado(a) Judicialmente"
},
{
    "id": "7",
    "valor": "União Estável"
}
]