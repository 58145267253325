import Contato from "../../Formularios/Contato";
import { Dependentes } from "../../Formularios/Dependentes";
import Documentos from "../../Formularios/Documentos";
import DadosPessoais from "../../Formularios/DadosPessoais";
import Nascimento from "../../Formularios/Nascimento";
import Documentacao from "../../Formularios/Documentacao";
import { DadosBancario } from "../../Formularios/DadosBancario";
import Endereco from "../../Formularios/Endereco";
import Finalizacao from "../../Formularios/Finalizacao";
import { Protocolo } from "../../Formularios/Protocolo";

export const passos = [
    {
        titulo: "Dados Pessoais",
        componente: (<>
            <DadosPessoais />
        </>)
    },
    {
        titulo: "Dados de Nascimento",
        componente: (<>
            <Nascimento />
        </>)
    },
    {
        titulo: "Documentação",
        componente: <Documentacao />
    },
    {
        titulo: "Dados Bancários",
        componente: <DadosBancario />
    },
    {
        titulo: "Endereço",
        componente: <Endereco />
    },
    {
        titulo: "Contato",
        componente: <Contato />
    },
    {
        titulo: "Dependentes",
        componente: (<Dependentes />)
    },
    {
        titulo: "Confirmação dos Dados",
        componente: <Finalizacao />
    },
    {
        titulo: "Anexar Identificação",
        componente: (
            <Documentos grupo="identificacao" />
        )
    },
    {
        titulo: "Anexar Comprovantes",
        componente: (
            <Documentos grupo="comprovante" />
        )
    },
    {
        titulo: "Anexar Certificados",
        componente: (
            <Documentos grupo="certificado" />
        )
    },
    {
        titulo:  "Certidões e Declarações",
        componente: (
            <Documentos grupo="certidao" />
        )
    },
    {
        titulo: "Outros Documentos",
        componente: (
            <Documentos grupo="outros" />
        )
    },
    {
        titulo: "Finalizado com sucesso!",
        componente: <Protocolo />
    }
];

export default passos;