import { Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import VisualizadorPDF from "../../Componentes/VisualizadorPDF";
import { baseUrl } from "../../Servicos/api";
import finishForm from "../../Servicos/finishForm";

const Finalizacao = () => {
    const [ficha, definirFicha] = useState<string>();
    useEffect(() => {
        finishForm().then(resposta => {
            definirFicha(baseUrl + "/files/" + resposta.anexo);
        })
    }, [])

    if (ficha) {
        return <>
            {/* <br />
            <Typography variant="body1">
                Verifique seus dados abaixo
            </Typography>
            <br /> */}
            {/* <Grid container component={Paper} spacing={2}>
                {campos.map((campo, indexCampo) => {
                    return <>
                        <Grid item xs={12} sm={6} md={campo[2]}>
                            <Typography>
                                {campo[0]}
                                <br />
                                <b>{campo[1]}</b>
                                <Divider />
                            </Typography>
                        </Grid>
                    </>
                })}
            </Grid> */}
            <br />
            <Typography>Você concluiu a parte cadastral, confirme seus dados abaixo e clique em próximo para iniciar o processo de anexar documentos.</Typography>
            <br />
            <VisualizadorPDF key={ficha} pdf={ficha} />
            <br />
            <Divider />
            <br />
        </>
    }
    return <></>
}

export default Finalizacao;