export default [
    {
        "id": "1",
        "valor": "001 - Banco do Brasil S.A"
    },
    {
        "id": "3",
        "valor": "003 - Banco da Amazônia S.A."
    },
    {
        "id": "4",
        "valor": "004 - Banco do Nordeste do Brasil S.A."
    },
    {
        "id": "7",
        "valor": "007 - BNDES Banco Nacional Do Desenvolvimento"
    },
    {
        "id": "10",
        "valor": "010 - CREDICOAMO CRÉDITO RURAL COOPERATIVA"
    },
    {
        "id": "12",
        "valor": "012 - Banco Inbursa"
    },
    {
        "id": "14",
        "valor": "014 - Natixis Brasil S.A."
    },
    {
        "id": "15",
        "valor": "015 - UBS Brasil Cctvm S.A."
    },
    {
        "id": "16",
        "valor": "016 - COOPERATIVA DE CRÉDITO MÚTUO DOS DESPAC"
    },
    {
        "id": "17",
        "valor": "017 - Bny Mellon Banco S.A."
    },
    {
        "id": "18",
        "valor": "018 - Banco Tricury S.A."
    },
    {
        "id": "21",
        "valor": "021 - Banco Banestes S.A."
    },
    {
        "id": "24",
        "valor": "024 - Banco Bandepe S.A."
    },
    {
        "id": "25",
        "valor": "025 - Banco Alfa S.A."
    },
    {
        "id": "29",
        "valor": "029 - Banco Itaú Consignado S.A."
    },
    {
        "id": "33",
        "valor": "033 - Banco Santander Brasil S.A."
    },
    {
        "id": "36",
        "valor": "036 - Banco Bradesco BBI S.A."
    },
    {
        "id": "37",
        "valor": "037 - Banco Do Estado Do Pará S.A."
    },
    {
        "id": "40",
        "valor": "040 - Banco Cargill S.A."
    },
    {
        "id": "41",
        "valor": "041 - Banrisul – Banco Do Estado Do Rio Grand"
    },
    {
        "id": "47",
        "valor": "047 - Banco do Estado de Sergipe S.A."
    },
    {
        "id": "60",
        "valor": "060 - Confidence Cc S.A."
    },
    {
        "id": "62",
        "valor": "062 - Hipercard Banco Múltiplo S.A."
    },
    {
        "id": "63",
        "valor": "063 - Banco Bradescard"
    },
    {
        "id": "64",
        "valor": "064 - Goldman Sachs Do Brasil Bm S.A."
    },
    {
        "id": "65",
        "valor": "065 - Banco Andbank S.A."
    },
    {
        "id": "66",
        "valor": "066 - Banco Morgan Stanley S.A."
    },
    {
        "id": "69",
        "valor": "069 - Banco Crefisa S.A."
    },
    {
        "id": "70",
        "valor": "070 - BRB – Banco De Brasília S.A."
    },
    {
        "id": "74",
        "valor": "074 - Banco J. Safra S.A."
    },
    {
        "id": "75",
        "valor": "075 - Bco Abn Amro S.A."
    },
    {
        "id": "76",
        "valor": "076 - Banco KDB Brasil S.A."
    },
    {
        "id": "77",
        "valor": "077 - Banco Inter S.A."
    },
    {
        "id": "78",
        "valor": "078 - Haitong Bi Do Brasil S.A."
    },
    {
        "id": "79",
        "valor": "079 - Banco Original Do Agronegócio S.A."
    },
    {
        "id": "80",
        "valor": "080 - B&T Cc Ltda"
    },
    {
        "id": "81",
        "valor": "081 - Banco Seguro S.A."
    },
    {
        "id": "82",
        "valor": "082 - Banco Topázio S.A."
    },
    {
        "id": "83",
        "valor": "083 - Banco Da China Brasil S.A."
    },
    {
        "id": "84",
        "valor": "084 - Uniprime Norte Do Paraná"
    },
    {
        "id": "85",
        "valor": "085 - Cooperativa Central de Créditos – Ailos"
    },
    {
        "id": "88",
        "valor": "088 - BANCO RANDON S.A."
    },
    {
        "id": "89",
        "valor": "089 - CREDISAN COOPERATIVA DE CRÉDITO"
    },
    {
        "id": "91",
        "valor": "091 - Unicred Central Rs"
    },
    {
        "id": "92",
        "valor": "092 - BRK S.A."
    },
    {
        "id": "93",
        "valor": "093 - PóloCred Scmepp Ltda"
    },
    {
        "id": "94",
        "valor": "094 - Banco Finaxis"
    },
    {
        "id": "95",
        "valor": "095 - Banco Confidence De Câmbio S.A."
    },
    {
        "id": "96",
        "valor": "096 - Banco B3 S.A."
    },
    {
        "id": "97",
        "valor": "097 - Credisis – Central de Cooperativas de C"
    },
    {
        "id": "98",
        "valor": "098 - Credialiança Ccr"
    },
    {
        "id": "99",
        "valor": "099 - Uniprime Central Ccc Ltda"
    },
    {
        "id": "100",
        "valor": "100 - Planner Corretora De Valores S.A."
    },
    {
        "id": "101",
        "valor": "101 - Renascença Dtvm Ltda"
    },
    {
        "id": "102",
        "valor": "102 - XP Investimentos S.A."
    },
    {
        "id": "104",
        "valor": "104 - Caixa Econômica Federal (CEF)"
    },
    {
        "id": "105",
        "valor": "105 - Lecca Cfi S.A."
    },
    {
        "id": "107",
        "valor": "107 - Banco Bocom BBM S.A."
    },
    {
        "id": "108",
        "valor": "108 - Portocred S.A."
    },
    {
        "id": "111",
        "valor": "111 - Banco Oliveira Trust Dtvm S.A."
    },
    {
        "id": "113",
        "valor": "113 - Magliano S.A."
    },
    {
        "id": "114",
        "valor": "114 - Central Cooperativa De Crédito no Estad"
    },
    {
        "id": "117",
        "valor": "117 - Advanced Cc Ltda"
    },
    {
        "id": "118",
        "valor": "118 - Standard Chartered Bi S.A."
    },
    {
        "id": "119",
        "valor": "119 - Banco Western Union do Brasil S.A."
    },
    {
        "id": "120",
        "valor": "120 - Banco Rodobens S.A."
    },
    {
        "id": "121",
        "valor": "121 - Banco Agibank S.A."
    },
    {
        "id": "122",
        "valor": "122 - Banco Bradesco BERJ S.A."
    },
    {
        "id": "124",
        "valor": "124 - Banco Woori Bank Do Brasil S.A."
    },
    {
        "id": "125",
        "valor": "125 - Brasil Plural S.A Banco"
    },
    {
        "id": "126",
        "valor": "126 - BR Partners Banco de Investimento S.A."
    },
    {
        "id": "127",
        "valor": "127 - Codepe Cvc S.A."
    },
    {
        "id": "128",
        "valor": "128 - Ms Bank S.A Banco De Câmbio"
    },
    {
        "id": "129",
        "valor": "129 - UBS Brasil Bi S.A."
    },
    {
        "id": "130",
        "valor": "130 - Caruana Scfi"
    },
    {
        "id": "131",
        "valor": "131 - Tullett Prebon Brasil Cvc Ltda"
    },
    {
        "id": "132",
        "valor": "132 - ICBC do Brasil Bm S.A."
    },
    {
        "id": "133",
        "valor": "133 - Cresol Confederação"
    },
    {
        "id": "134",
        "valor": "134 - BGC Liquidez Dtvm Ltda"
    },
    {
        "id": "136",
        "valor": "136 - Unicred Cooperativa LTDA"
    },
    {
        "id": "137",
        "valor": "137 - Multimoney Cc Ltda"
    },
    {
        "id": "138",
        "valor": "138 - Get Money Cc Ltda"
    },
    {
        "id": "139",
        "valor": "139 - Intesa Sanpaolo Brasil S.A."
    },
    {
        "id": "140",
        "valor": "140 - Easynvest – Título Cv S.A."
    },
    {
        "id": "142",
        "valor": "142 - Broker Brasil Cc Ltda"
    },
    {
        "id": "143",
        "valor": "143 - Treviso Cc S.A."
    },
    {
        "id": "144",
        "valor": "144 - Bexs Banco De Cambio S.A."
    },
    {
        "id": "145",
        "valor": "145 - Levycam Ccv Ltda"
    },
    {
        "id": "146",
        "valor": "146 - Guitta Cc Ltda"
    },
    {
        "id": "149",
        "valor": "149 - Facta S.A. Cfi"
    },
    {
        "id": "157",
        "valor": "157 - Icap Do Brasil Ctvm Ltda"
    },
    {
        "id": "159",
        "valor": "159 - Casa do Crédito S.A."
    },
    {
        "id": "163",
        "valor": "163 - Commerzbank Brasil S.A Banco Múltiplo"
    },
    {
        "id": "169",
        "valor": "169 - Banco Olé Bonsucesso Consignado S.A."
    },
    {
        "id": "172",
        "valor": "172 - Albatross Ccv S.A."
    },
    {
        "id": "173",
        "valor": "173 - BRL Trust Dtvm Sa"
    },
    {
        "id": "174",
        "valor": "174 - Pernambucanas Financ S.A."
    },
    {
        "id": "177",
        "valor": "177 - Guide Investimentos S.A. Corretora de V"
    },
    {
        "id": "180",
        "valor": "180 - Cm Capital Markets Cctvm Ltda"
    },
    {
        "id": "182",
        "valor": "182 - Dacasa Financeira S/A"
    },
    {
        "id": "183",
        "valor": "183 - Socred S.A."
    },
    {
        "id": "184",
        "valor": "184 - Banco Itaú BBA S.A."
    },
    {
        "id": "188",
        "valor": "188 - Ativa S.A Investimentos"
    },
    {
        "id": "189",
        "valor": "189 - Hs Financeira"
    },
    {
        "id": "190",
        "valor": "190 - Servicoop"
    },
    {
        "id": "191",
        "valor": "191 - Nova Futura Ctvm Ltda"
    },
    {
        "id": "194",
        "valor": "194 - Parmetal Dtvm Ltda"
    },
    {
        "id": "196",
        "valor": "196 - Banco Fair Cc S.A."
    },
    {
        "id": "197",
        "valor": "197 - Stone Pagamentos S.A."
    },
    {
        "id": "204",
        "valor": "204 - Banco Bradesco Cartoes S.A."
    },
    {
        "id": "208",
        "valor": "208 - Banco BTG Pactual S.A."
    },
    {
        "id": "212",
        "valor": "212 - Banco Original S.A."
    },
    {
        "id": "213",
        "valor": "213 - Bco Arbi S.A."
    },
    {
        "id": "217",
        "valor": "217 - Banco John Deere S.A."
    },
    {
        "id": "218",
        "valor": "218 - Banco Bs2 S.A."
    },
    {
        "id": "222",
        "valor": "222 - Banco Crédit Agricole Br S.A."
    },
    {
        "id": "224",
        "valor": "224 - Banco Fibra S.A."
    },
    {
        "id": "233",
        "valor": "233 - Banco Cifra S.A."
    },
    {
        "id": "237",
        "valor": "237 - Bradesco S.A."
    },
    {
        "id": "241",
        "valor": "241 - Banco Classico S.A."
    },
    {
        "id": "243",
        "valor": "243 - Banco Máxima S.A."
    },
    {
        "id": "246",
        "valor": "246 - Banco Abc Brasil S.A."
    },
    {
        "id": "249",
        "valor": "249 - Banco Investcred Unibanco S.A."
    },
    {
        "id": "250",
        "valor": "250 - Banco Bcv"
    },
    {
        "id": "253",
        "valor": "253 - Bexs Cc S.A."
    },
    {
        "id": "254",
        "valor": "254 - Paraná Banco S.A."
    },
    {
        "id": "259",
        "valor": "259 - MONEYCORP BANCO DE CÂMBIO S.A."
    },
    {
        "id": "260",
        "valor": "260 - Nu Pagamentos S.A (Nubank)"
    },
    {
        "id": "265",
        "valor": "265 - Banco Fator S.A."
    },
    {
        "id": "266",
        "valor": "266 - Banco Cedula S.A."
    },
    {
        "id": "268",
        "valor": "268 - Barigui Companhia Hipotecária"
    },
    {
        "id": "269",
        "valor": "269 - Hsbc Banco De Investimento"
    },
    {
        "id": "270",
        "valor": "270 - Sagitur Cc Ltda"
    },
    {
        "id": "271",
        "valor": "271 - Ib Cctvm Ltda"
    },
    {
        "id": "272",
        "valor": "272 - AGK Corretora de Câmbio S.A."
    },
    {
        "id": "273",
        "valor": "273 - Ccr De São Miguel Do Oeste"
    },
    {
        "id": "274",
        "valor": "274 - MONEY PLUS SOCIEDADE DE CRÉDITO AO MICR"
    },
    {
        "id": "276",
        "valor": "276 - Senff S.A."
    },
    {
        "id": "278",
        "valor": "278 - Genial Investimentos Cvm S.A."
    },
    {
        "id": "279",
        "valor": "279 - Ccr De Primavera Do Leste"
    },
    {
        "id": "280",
        "valor": "280 - Avista S.A."
    },
    {
        "id": "281",
        "valor": "281 - Cooperativa de Crédito Rural Coopavel"
    },
    {
        "id": "283",
        "valor": "283 - RB Capital Investimentos Dtvm Ltda"
    },
    {
        "id": "285",
        "valor": "285 - Frente Cc Ltda"
    },
    {
        "id": "286",
        "valor": "286 - Cooperativa de Crédito Rural de De Ouro"
    },
    {
        "id": "288",
        "valor": "288 - Carol Dtvm Ltda"
    },
    {
        "id": "289",
        "valor": "289 - DECYSEO CORRETORA DE CAMBIO LTDA."
    },
    {
        "id": "290",
        "valor": "290 - PagSeguro Internet S.A."
    },
    {
        "id": "292",
        "valor": "292 - BS2 Distribuidora De Títulos E Investim"
    },
    {
        "id": "293",
        "valor": "293 - Lastro Rdv Dtvm Ltda"
    },
    {
        "id": "296",
        "valor": "296 - VISION S.A. CORRETORA DE CAMBIO"
    },
    {
        "id": "298",
        "valor": "298 - Vip’s Cc Ltda"
    },
    {
        "id": "299",
        "valor": "299 - SOROCRED   CRÉDITO, FINANCIAMENTO E INV"
    },
    {
        "id": "300",
        "valor": "300 - Banco La Nacion Argentina"
    },
    {
        "id": "301",
        "valor": "301 - BPP Instituição De Pagamentos S.A."
    },
    {
        "id": "306",
        "valor": "306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VAL"
    },
    {
        "id": "307",
        "valor": "307 - Terra Investimentos Distribuidora de Tí"
    },
    {
        "id": "309",
        "valor": "309 - CAMBIONET CORRETORA DE CÂMBIO LTDA."
    },
    {
        "id": "310",
        "valor": "310 - VORTX Dtvm Ltda"
    },
    {
        "id": "313",
        "valor": "313 - AMAZÔNIA CORRETORA DE CÂMBIO LTDA."
    },
    {
        "id": "315",
        "valor": "315 - PI Distribuidora de Títulos e Valores M"
    },
    {
        "id": "318",
        "valor": "318 - Banco BMG S.A."
    },
    {
        "id": "319",
        "valor": "319 - OM DISTRIBUIDORA DE TÍTULOS E VALORES M"
    },
    {
        "id": "320",
        "valor": "320 - China Construction Bank – Ccb Brasil S."
    },
    {
        "id": "321",
        "valor": "321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMP"
    },
    {
        "id": "322",
        "valor": "322 - Cooperativa de Crédito Rural de Abelard"
    },
    {
        "id": "323",
        "valor": "323 - Mercado Pago – Conta Do Mercado Livre"
    },
    {
        "id": "324",
        "valor": "324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A."
    },
    {
        "id": "325",
        "valor": "325 - Órama Distribuidora de Títulos e Valore"
    },
    {
        "id": "326",
        "valor": "326 - PARATI – CRÉDITO, FINANCIAMENTO E INVES"
    },
    {
        "id": "329",
        "valor": "329 - QI Sociedade de Crédito Direto S.A."
    },
    {
        "id": "330",
        "valor": "330 - Banco Bari de Investimentos e Financiam"
    },
    {
        "id": "331",
        "valor": "331 - Fram Capital Distribuidora de Títulos e"
    },
    {
        "id": "332",
        "valor": "332 - Acesso Soluções de Pagamento S.A."
    },
    {
        "id": "335",
        "valor": "335 - Banco Digio S.A."
    },
    {
        "id": "336",
        "valor": "336 - Banco C6 S.A – C6 Bank"
    },
    {
        "id": "340",
        "valor": "340 - Super Pagamentos S/A (Superdital)"
    },
    {
        "id": "341",
        "valor": "341 - Itaú Unibanco S.A."
    },
    {
        "id": "342",
        "valor": "342 - Creditas Sociedade de Crédito Direto S."
    },
    {
        "id": "343",
        "valor": "343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREE"
    },
    {
        "id": "348",
        "valor": "348 - Banco XP S/A"
    },
    {
        "id": "349",
        "valor": "349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVES"
    },
    {
        "id": "350",
        "valor": "350 - Cooperativa De Crédito Rural De Pequeno"
    },
    {
        "id": "352",
        "valor": "352 - TORO CORRETORA DE TÍTULOS E VALORES MOB"
    },
    {
        "id": "354",
        "valor": "354 - NECTON INVESTIMENTOS S.A. CORRETORA DE "
    },
    {
        "id": "355",
        "valor": "355 - ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A."
    },
    {
        "id": "359",
        "valor": "359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIME"
    },
    {
        "id": "360",
        "valor": "360 - TRINUS Capital Distribuidora De Títulos"
    },
    {
        "id": "362",
        "valor": "362 - CIELO S.A."
    },
    {
        "id": "363",
        "valor": "363 - SOCOPA SOCIEDADE CORRETORA PAULISTA S.A"
    },
    {
        "id": "364",
        "valor": "364 - GERENCIANET S.A."
    },
    {
        "id": "365",
        "valor": "365 - SOLIDUS S.A. CORRETORA DE CAMBIO E VALO"
    },
    {
        "id": "366",
        "valor": "366 - Banco Societe Generale Brasil"
    },
    {
        "id": "367",
        "valor": "367 - VITREO DISTRIBUIDORA DE TÍTULOS E VALOR"
    },
    {
        "id": "368",
        "valor": "368 - Banco CSF S.A."
    },
    {
        "id": "370",
        "valor": "370 - Banco Mizuho S.A."
    },
    {
        "id": "371",
        "valor": "371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS"
    },
    {
        "id": "373",
        "valor": "373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESS"
    },
    {
        "id": "374",
        "valor": "374 - REALIZE CRÉDITO, FINANCIAMENTO E INVEST"
    },
    {
        "id": "376",
        "valor": "376 - Banco J.P. Morgan S.A."
    },
    {
        "id": "377",
        "valor": "377 - MS SOCIEDADE DE CRÉDITO AO MICROEMPREEN"
    },
    {
        "id": "378",
        "valor": "378 - BBC LEASING S.A. – Arrendamento Mercant"
    },
    {
        "id": "379",
        "valor": "379 - COOPERFORTE – Cooperativa De Economia E"
    },
    {
        "id": "380",
        "valor": "380 - PICPAY SERVICOS S.A."
    },
    {
        "id": "381",
        "valor": "381 - BANCO MERCEDES-BENZ DO BRASIL S.A."
    },
    {
        "id": "382",
        "valor": "382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEM"
    },
    {
        "id": "383",
        "valor": "383 - BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAME"
    },
    {
        "id": "384",
        "valor": "384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO"
    },
    {
        "id": "387",
        "valor": "387 - Banco Toyota do Brasil S.A."
    },
    {
        "id": "389",
        "valor": "389 - Banco Mercantil Do Brasil S.A."
    },
    {
        "id": "390",
        "valor": "390 - BANCO GM S.A."
    },
    {
        "id": "391",
        "valor": "391 - COOPERATIVA DE CRÉDITO RURAL DE IBIAM –"
    },
    {
        "id": "393",
        "valor": "393 - Banco Volkswagen S.A."
    },
    {
        "id": "394",
        "valor": "394 - Banco Bradesco Financiamentos S.A."
    },
    {
        "id": "396",
        "valor": "396 - HUB PAGAMENTOS S.A."
    },
    {
        "id": "397",
        "valor": "397 - LISTO SOCIEDADE DE CRÉDITO DIRETO S.A."
    },
    {
        "id": "399",
        "valor": "399 - Kirton Bank S.A. – Banco Múltiplo"
    },
    {
        "id": "403",
        "valor": "403 - CORA SOCIEDADE DE CRÉDITO DIRETO S.A."
    },
    {
        "id": "404",
        "valor": "404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A."
    },
    {
        "id": "408",
        "valor": "408 - BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S"
    },
    {
        "id": "412",
        "valor": "412 - Banco Capital S.A."
    },
    {
        "id": "422",
        "valor": "422 - Banco Safra S.A."
    },
    {
        "id": "456",
        "valor": "456 - Banco MUFG Brasil S.A."
    },
    {
        "id": "464",
        "valor": "464 - Banco Sumitomo Mitsui Brasil S.A."
    },
    {
        "id": "473",
        "valor": "473 - Banco Caixa Geral Brasil S.A."
    },
    {
        "id": "477",
        "valor": "477 - Citibank N.A."
    },
    {
        "id": "479",
        "valor": "479 - Banco ItauBank S.A."
    },
    {
        "id": "487",
        "valor": "487 - Deutsche Bank S.A (Banco Alemão)"
    },
    {
        "id": "488",
        "valor": "488 - Jpmorgan Chase Bank"
    },
    {
        "id": "492",
        "valor": "492 - Ing Bank N.V."
    },
    {
        "id": "494",
        "valor": "494 - Banco Rep Oriental Uruguay"
    },
    {
        "id": "495",
        "valor": "495 - La Provincia Buenos Aires Banco"
    },
    {
        "id": "505",
        "valor": "505 - Banco Credit Suisse (Brl) S.A."
    },
    {
        "id": "545",
        "valor": "545 - Senso Ccvm S.A."
    },
    {
        "id": "600",
        "valor": "600 - Banco Luso Brasileiro S.A."
    },
    {
        "id": "604",
        "valor": "604 - Banco Industrial Do Brasil S.A."
    },
    {
        "id": "610",
        "valor": "610 - Banco VR S.A."
    },
    {
        "id": "611",
        "valor": "611 - Banco Paulista"
    },
    {
        "id": "612",
        "valor": "612 - Banco Guanabara S.A."
    },
    {
        "id": "613",
        "valor": "613 - Omni Banco S.A."
    },
    {
        "id": "623",
        "valor": "623 - Banco Pan S.A."
    },
    {
        "id": "626",
        "valor": "626 - Banco Ficsa S.A."
    },
    {
        "id": "630",
        "valor": "630 - Banco Intercap S.A."
    },
    {
        "id": "633",
        "valor": "633 - Banco Rendimento S.A."
    },
    {
        "id": "634",
        "valor": "634 - Banco Triangulo S.A (Banco Triângulo)"
    },
    {
        "id": "637",
        "valor": "637 - Banco Sofisa S.A (Sofisa Direto)"
    },
    {
        "id": "641",
        "valor": "641 - Banco Alvorada S.A."
    },
    {
        "id": "643",
        "valor": "643 - Banco Pine S.A."
    },
    {
        "id": "652",
        "valor": "652 - Itaú Unibanco Holding Bm S.A."
    },
    {
        "id": "653",
        "valor": "653 - Banco Indusval S.A."
    },
    {
        "id": "654",
        "valor": "654 - Banco A.J. Renner S.A."
    },
    {
        "id": "655",
        "valor": "655 - Banco Votorantim S.A."
    },
    {
        "id": "707",
        "valor": "707 - Banco Daycoval S.A."
    },
    {
        "id": "712",
        "valor": "712 - Banco Ourinvest S.A."
    },
    {
        "id": "739",
        "valor": "739 - Banco Cetelem S.A."
    },
    {
        "id": "741",
        "valor": "741 - Banco Ribeirão Preto S.A."
    },
    {
        "id": "743",
        "valor": "743 - Banco Semear S.A."
    },
    {
        "id": "745",
        "valor": "745 - Banco Citibank S.A."
    },
    {
        "id": "746",
        "valor": "746 - Banco Modal S.A."
    },
    {
        "id": "747",
        "valor": "747 - Banco Rabobank Internacional Do Brasil "
    },
    {
        "id": "748",
        "valor": "748 - Banco Cooperativa Sicredi S.A."
    },
    {
        "id": "751",
        "valor": "751 - Scotiabank Brasil"
    },
    {
        "id": "752",
        "valor": "752 - Banco BNP Paribas Brasil S.A."
    },
    {
        "id": "753",
        "valor": "753 - Novo Banco Continental S.A Bm"
    },
    {
        "id": "754",
        "valor": "754 - Banco Sistema S.A."
    },
    {
        "id": "755",
        "valor": "755 - Bofa Merrill Lynch Bm S.A."
    },
    {
        "id": "756",
        "valor": "756 - Bancoob – Banco Cooperativo Do Brasil S"
    },
    {
        "id": "757",
        "valor": "757 - Banco Keb Hana Do Brasil S.A."
    }
];