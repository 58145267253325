import { Delete, ExpandMore, Remove } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Fab, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CampoSeletor from "../../Componentes/CampoSeletor";
import CampoTexto, { powerUp } from "../../Componentes/CampoTexto";
import contextoDados from "../../Contextos/contextoDados";

interface propriedadesFuncaoAlteradora {
    indexDependente: number,
    campo: 'TPD_Nome' | 'TPD_Data_Nascimento' | 'TPD_CPF' | 'Naturalidade' | 'TipoDependente_id';
    valor: string
}

const DependentesContainer = () => {
    const { estado, acoes } = useContext(contextoDados);
    if (estado && estado.dados && estado.dados.dependentes) {
        const dependentes = estado.dados.dependentes;
        return <>
            <Typography>
                <Button disabled>
                    {dependentes.length === 0 && "Nenhum dependente cadastrado."}
                    {dependentes.length > 0 && `${dependentes.length} ${dependentes.length === 1 ? "dependente foi cadastrado" : "dependentes foram cadastrados"}`}
                </Button>
                <div style={{ float: 'right', display: "inline" }}>
                    <Button onClick={() => {
                        if (estado && estado.dados && estado.dados.dependentes) {
                            acoes.definirEstado({
                                ...estado,
                                dados: {
                                    ...estado.dados,
                                    dependentes: [...estado.dados.dependentes, {}]
                                }
                            });
                        }
                    }}>Adicionar Dependente</Button>
                </div>
            </Typography>

            <br />
        </>
    }
    return <></>
}

const CartaoDependente = ({ indexDependente }: { indexDependente: number }) => {

    const { estado, acoes } = useContext(contextoDados);
    const dependente = estado.dados?.dependentes.find((_, indexN) => indexN === indexDependente);

    const definirCampoDependente = ({ indexDependente, campo, valor }: propriedadesFuncaoAlteradora) => {
        if (estado && estado.dados && estado.dados.dependentes) {
            acoes.definirEstado({
                ...estado,
                dados: {
                    ...estado.dados,
                    dependentes: [
                        ...estado.dados.dependentes.map((dependenteN, indexDependenteN) => {
                            if (indexDependenteN === indexDependente) {
                                dependenteN[campo] = valor;
                            }
                            return dependenteN
                        }),
                    ]
                }
            });
        }
        return (_: string) => { }
    }

    if (dependente) {
        const campos: {
            titulo: string,
            chave: "TPD_Nome" | "TPD_Data_Nascimento" | "TPD_CPF" | "Naturalidade" | "TipoDependente_id",
            tipo: "data" | "numerico" | "senha" | "texto" | "radio",
            powerUps?: powerUp[],
            maxLength?: number
        }[] = [
                {
                    titulo: "Tipo de Dependente",
                    chave: 'TipoDependente_id',
                    tipo: "radio"
                },
                {
                    titulo: "Nome",
                    chave: 'TPD_Nome',
                    tipo: "texto",
                    powerUps: ["caixaAlta"],
                    maxLength: 60
                },
                {
                    titulo: "Data de Nascimento",
                    chave: 'TPD_Data_Nascimento',
                    tipo: "data"
                },
                {
                    titulo: "CPF",
                    chave: 'TPD_CPF',
                    tipo: "texto",
                    powerUps: ["caixaAlta", "somenteDigitos"],
                    maxLength: 11
                },
                {
                    titulo: "Naturalidade",
                    chave: 'Naturalidade',
                    tipo: "texto",
                    powerUps: ["caixaAlta"],
                    maxLength: 60
                },
            ];

        return <>
            <Grid container spacing={2}>
                {campos.map(campo => {
                    if (campo.tipo === "radio") {
                        return <>
                            <Grid item xs={8}><CampoSeletor
                                valor={dependente[campo.chave] || ""}
                                funcaoAlteradora={(novoValor) => definirCampoDependente({
                                    indexDependente,
                                    campo: campo.chave,
                                    valor: novoValor
                                })}
                                titulo={campo.titulo}
                                tipo="radio"
                                valores={[{ id: '1', valor: "Masculino" }, { id: '2', valor: "Feminino" }]}
                            /></Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                <Button variant="contained" size="small" color="error" onClick={() => {
                                    estado && estado.dados && estado.dados.dependentes && acoes.definirEstado({
                                        ...estado,
                                        dados: {
                                            ...estado.dados,
                                            dependentes: estado.dados.dependentes.filter((_, index) => index !== indexDependente)
                                        }
                                    })
                                }}>
                                    <Delete />
                                </Button>
                            </Grid>
                        </>
                    }
                    return <Grid item xs={12} md={6}><CampoTexto
                        valor={dependente[campo.chave] || ""}
                        funcaoAlteradora={(novoValor) => definirCampoDependente({
                            indexDependente: indexDependente,
                            campo: campo.chave,
                            valor: novoValor
                        })}
                        titulo={campo.titulo}
                        tipo={campo.tipo}
                        powerUps={campo.powerUps}
                    /></Grid>
                })}
            </Grid>
            {/* </CardContent>
            </Card> */}
        </>
    }
    return <></>
}
export const Dependentes = () => {
    const { estado, acoes } = useContext(contextoDados);

    const [dependenteSelecionado, definirDependenteSelecionado] = useState(0);

    const quantidade = estado.dados?.dependentes?.length;

    useEffect(() => {
        if (quantidade && quantidade > 0) {
            definirDependenteSelecionado(quantidade - 1);
        }
    }, [quantidade])

    if (estado && estado.dados && estado.dados.dependentes) {
        const dependentes = estado.dados.dependentes;
        return <>
            <DependentesContainer />
            <br /><br />
            {dependentes.map((dependente, indexDependente) => {
                return <Accordion key={"accordion-" + indexDependente} expanded={dependenteSelecionado === indexDependente} onChange={() => {
                    definirDependenteSelecionado(dependenteSelecionado === indexDependente ? -1 : indexDependente);
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel-${indexDependente}-content`}
                        id={`panel-${indexDependente}-header`}
                    >
                        <Typography>
                            {dependente.TPD_Nome ? dependente.TPD_Nome : `Dependente N° ${indexDependente + 1}`}
                        </Typography>
                        {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <CartaoDependente indexDependente={indexDependente} />
                    </AccordionDetails>
                </Accordion>
            })}
        </>

        // if (dependentes.length > 0) {
        //     return <>
        //         {/* <Card style={{ borderBottom: '2px solid gray;' }}>
        //             <Tabs
        //                 indicatorColor="secondary"
        //                 textColor="inherit"
        //                 variant="fullWidth"
        //                 scrollButtons="auto"
        //                 value={dependenteSelecionado} onChange={(_, e) => {
        //                     definirDependenteSelecionado(e);
        //                 }}
        //             >
        //                 {dependentes.map((dependente, dependenteIndex) => {
        //                     return <Tab
        //                         label={<>
        //                             {dependente.TPD_Nome?.split(' ')[0] || "n° " + (dependenteIndex + 1)}
        //                         </>}
        //                     />
        //                 })}
        //             </Tabs>
        //             <CartaoDependente indexDependente={dependenteSelecionado} />
        //         </Card> */}

        //     </>
        // }
        return <></>
    }
    return <></>
}