import { useContext } from "react";
import contextoDados from "../Contextos/contextoDados";


export const useCriarFuncaoAlteradora = ({ chave, contexto }: { chave: any, contexto?: "geral" | "dadosServidor" }) => {
    if (!contexto) {
        contexto = "dadosServidor";
    }
    const { estado, acoes } = useContext(contextoDados);
    if (contexto === "geral") {
        return [
            /* @ts-ignore */
            estado[chave],
            (valor: string) => acoes.definirEstado({
                ...estado,
                [chave]: valor
            })
        ]
    }
    else {
        return [
            (() => {
                // console.log(estado);
                if (estado && estado.dados && estado.dados.servidor) {
                    /* @ts-ignore */
                    // console.log(estado.dados.servidor[chave]);
                    /* @ts-ignore */
                    return estado.dados.servidor[chave];

                }
                return ""
            })(),
            (valor: string) => {
                if (estado && estado.dados && estado.dados.servidor) {
                    acoes.definirEstado({
                        ...estado,
                        dados: {
                            ...estado.dados,
                            servidor: {
                                ...estado.dados.servidor,
                                [chave]: valor
                            }
                        },
                    });
                }
            }
        ]
    }
}

export default useCriarFuncaoAlteradora;