import { api } from "./api";

export interface RequisicaoUpdateData {
    "SRV_Foto"?: string,
    "SRV_Email"?: string,
    "SRV_UF_Nascimento"?: string,
    "SRV_Cidade_Nascimento"?: string,
    "SRV_Data_Nascimento"?: string,
    "SRV_Sexo"?: "M" | "F",
    "GrauInstrucao_id"?: string,
    "SRV_NumFilhos"?: string,
    "SRV_Nome_Pai"?: string,
    "SRV_Nome_Mae"?: string,
    "TipoEstadoCivil_id"?: string,
    "SRV_Nome_Conjuge"?: string,
    "SRV_RG"?: string,
    "SRV_RG_Orgao_Expedidor"?: string,
    "SRV_RG_Data_Expedicao"?: string,
    "SRV_CP_Numero"?: string,
    "SRV_CP_Serie"?: string,
    "SRV_CP_Data_Emissao"?: string,
    "SRV_Pis_Pasep"?: string,
    "SRV_CR_Numero"?: string,
    "SRV_CR_Serie"?: string,
    "SRV_CR_Unidade"?: string,
    "SRV_TE_Numero"?: string,
    "SRV_TE_Zona"?: string,
    "SRV_TE_Secao"?: string,
    "Bco_Codigo"?: string,
    "Bco_Conta"?: string,
    "Bco_Agencia"?: string,
    "Bco_Operacao"?: string,
    "End_Logradouro"?: string,
    "End_Numero"?: string,
    "End_Complemento"?: string,
    "End_Bairro"?: string,
    "End_CEP"?: string,
    "End_Cidade"?: string,
    "End_UF"?: string,
    "Tel_Numero1"?: string,
    "Tel_Numero2"?: string,
    // "Data_Alteracao"?: string,
    "dependentes"?: {
        "TPD_Nome"?: string
        "TPD_Data_Nascimento"?: string
        "TPD_CPF"?: string
        "Naturalidade"?: string
        "TipoDependente_id"?: string
    }[],
    "tipocor_id"?: string
    // "documentos"?: {
    //     TipoDocumento_id: string
    //     anexo: string
    // }[]
}

export interface RespostaUpdateData {
    status: boolean,
    message: string,
    campos: {
        ProtocoloEntrega?: string,
        DataEntrega?: string,
        status?: string
    }
}

export const updateData = async (requisicao: RequisicaoUpdateData) => {
    const resposta: RespostaUpdateData = await api("/updateData/", "POST", requisicao);
    return resposta;
}

export default updateData;