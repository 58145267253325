import { Stepper, Step, StepLabel, StepContent, Button, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import contextoDados from "../../Contextos/contextoDados";
import passos from "./passos";

export const StepperDinamico = () => {
    const [passoAtivo, definirPassoAtivo] = useState(0);
    const { estado, acoes } = useContext(contextoDados);

    const passoServidor = estado.dados?.servidor.passo;

    useEffect(() => {
        if (passoServidor && Number(passoServidor) + 1 !== passoAtivo) {
            definirPassoAtivo(Number(passoServidor) + 1);
        }
    }, [passoServidor]);

    const ref = useRef<HTMLSpanElement[]>([]);

    useEffect(() => {
        setTimeout(() => {
            const top = ref.current.find(r => r.dataset.ativo === "1")?.offsetTop;
            if(passoAtivo > 0 && ref.current.length > 0 && top) {
                window.scrollTo(0, top - 60);
            }
        }, 500);
    }, [passoAtivo])

    return <Stepper variant="elevation" orientation="vertical" activeStep={passoAtivo}>
        {passos.map((passo, indexPasso) => {
            if (indexPasso > passoAtivo + 1) {
                return <></>
            }
            return <Step>
                <StepLabel data-ativo={indexPasso === passoAtivo ? "1" : undefined} ref={(el: HTMLSpanElement) => ref.current[indexPasso] = el} onClick={() => {
                    // if (indexPasso < passoAtivo
                    //     && estado.dados
                    //     && ["Convocado", "Cadastro", "Documento", "Indeferido"].includes(estado.dados.servidor.status)
                    // ) {
                    //     definirPassoAtivo(indexPasso);
                    // }
                }}>
                    {passo.titulo}
                </StepLabel>
                <StepContent>
                    {passo.componente}
                    <div style={{ textAlign: "right", marginTop: '20px' }}>
                        {passoAtivo !== 0 && passoAtivo !== 13 && (
                            <Button onClick={() => {
                                definirPassoAtivo(passoAtivo - 1);
                            }}>Voltar</Button>
                        )}
                        {passoAtivo !== 13 && (
                            <Button variant="contained" onClick={async () => {
                                const valido = await acoes.validarPassoStepper(passoAtivo);
                                if (valido) {
                                    definirPassoAtivo(passoAtivo + 1);
                                }
                            }}>Próximo</Button>
                        )}
                    </div>
                </StepContent>
            </Step>
        })}
    </Stepper >
}

export default StepperDinamico;