import { MobileDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export type powerUp = "caixaAlta" | "somenteDigitos" | "desligado";

interface Propriedades {
    titulo: string,
    valor: string,
    funcaoAlteradora: (valor: string) => void,
    tipo?: "texto" | "numerico" | "senha" | "data",
    maxLength?: number,
    powerUps?: powerUp[]
}

export const CampoTexto = ({ titulo, valor, funcaoAlteradora, tipo, powerUps, maxLength }: Propriedades) => {

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [localState, setLocalState] = useState(valor || "");

    useEffect(() => {
        if (localState !== valor) {
            setLocalState(valor);
        }
    }, [valor])

    // useEffect(() => {
    //     if (localState) {
    //         if (timer) {
    //             clearTimeout(timer);
    //             setTimer(undefined);
    //         }
    //         setTimer(
    //             setTimeout(() => {
    //                 funcaoAlteradora(localState);
    //             }, 700)
    //         );
    //     }

    // }, [localState])

    if (!tipo) {
        tipo = "texto";
    }

    if (tipo === "data") {

        var mySQLDate = valor;
        const dataValor = new Date(Date.parse(mySQLDate.replace(/-/g, '/')));

        return <MobileDatePicker
            label={titulo}
            value={dataValor || null}
            onChange={e => {
                function formatDate(date: Date) {
                    var d = new Date(date),
                        month = '' + (d.getMonth() + 1),
                        day = '' + d.getDate(),
                        year = d.getFullYear();
                
                    if (month.length < 2) 
                        month = '0' + month;
                    if (day.length < 2) 
                        day = '0' + day;
                
                    return [year, month, day].join('-');
                }
                
                funcaoAlteradora(formatDate(new Date(e || "")));
            }}
            renderInput={(params) => <TextField
                {...params}
                fullWidth
                InputLabelProps={{ shrink: true }}
            />}
            views={['year', 'month', 'day']}
            disableFuture
            openTo="year"
            disableMaskedInput
            cancelText="Cancelar"
            okText="OK"
            disabled={powerUps?.includes("desligado") ? true : false}
        />
    }
    return <TextField
        label={titulo}
        inputProps={{
            autoComplete: 'new-password', // disable autocomplete and autofill
            maxLength
        }}
        InputLabelProps={{ shrink: true }}
        value={localState}
        onChange={(e) => {
            setLocalState(e.target.value || "")
        }}
        onInput={(e: any) => {
            if (powerUps?.includes("caixaAlta")) e.target.value = ("" + e.target.value).toUpperCase();
            if (powerUps?.includes("somenteDigitos")) e.target.value = (e.target.value.match(/\d/g) ? e.target.value.match(/\d/g).join("") : "")
        }}
        onBlur={() => {
            funcaoAlteradora(localState);
        }}
        type={(() => {
            if (tipo === "texto") return "text";
            if (tipo === "numerico" || powerUps?.includes("somenteDigitos")) return "tel";
            if (tipo === "senha") return "password";
        })()}
        fullWidth
        disabled={powerUps?.includes("desligado") ? true : false}

    />
}

export default CampoTexto;