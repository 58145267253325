import { Typography } from "@mui/material";
import saudacao from "../../Funcoes/saudacao";

export const BemVindo = () => {
    return <>
        <Typography variant="body1" component="span">
            
        Antes de iniciar o seu cadastro, certifique-se de que você possui as informações e documentações necessárias para realização do mesmo, conforme solicitado no edital. <br/><br/>
        Qualquer dúvida favor entrar em contato no Setor Pessoal da Secretaria Municipal de Educação de São José.

        </Typography>
    </>;
}

export default BemVindo;