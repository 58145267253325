export default [
    {
        "id": "1",
        "tipo": "1",
        "titulo": "Carteira de Identidade",
        "descricao": "01 (uma) cópia da Carteira de Identidade;",
        "grupo": "identificacao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "2",
        "tipo": "1",
        "titulo": "Título de Eleitor",
        "descricao": "01 (uma) cópia do Título de Eleitor;",
        "grupo": "identificacao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "10",
        "tipo": "1",
        "titulo": "PIS/PASEP",
        "descricao": "01 (uma) cópia do PIS/PASEP;",
        "grupo": "identificacao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "11",
        "tipo": "1",
        "titulo": "CPF",
        "descricao": "01 (uma) cópia do CPF;",
        "grupo": "identificacao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "14",
        "tipo": "1",
        "titulo": "Carteira de Trabalho",
        "descricao": "01 (uma) cópia da Carteira de Trabalho da página da fotografia (frente e verso), onde consta a data da emissão. Carteira expedida pela Delegacia Regional do Trabalho;",
        "grupo": "identificacao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "3",
        "tipo": "1",
        "titulo": "Comprovante de Votação",
        "descricao": "01 (uma) cópia do comprovante de votação da última eleição;",
        "grupo": "comprovante",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "16",
        "tipo": "1",
        "titulo": "Carteira de Vacinação para Rubéola",
        "descricao": "01 (uma) cópia da carteira que comprove vacinação monovalente para rubéola (se mulher, com idade até 40 anos incompletos);",
        "grupo": "comprovante",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "17",
        "tipo": "1",
        "titulo": "Comprovante de Vacinação Coronavírus COVID-19",
        "descricao": "01 (uma) cópia do comprovante de vacinação 2 (duas) doses (ou dose única) da vacina contra Coronavírus (COVID-19);",
        "grupo": "comprovante",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "21",
        "tipo": "1",
        "titulo": "Comprovante de Residência",
        "descricao": "01 (uma) cópia do comprovante de residência recente: (água, luz ou telefone);",
        "grupo": "comprovante",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "4",
        "tipo": "1",
        "titulo": "Certificado de Reservista",
        "descricao": "01 (uma) cópia do certificado de reservista (sexo masculino)",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "5",
        "tipo": "1",
        "titulo": "Certificado de Comprovação de Habilitação",
        "descricao": "01 (uma) cópia  FRENTE e VERSO do certificado que comprove habilitação (escolaridade) exigida pelo cargo, de acordo com o edital (diploma de magistério ou diploma do 3º grau/nível superior - Licenciatura);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "7",
        "tipo": "1",
        "titulo": "Certificados de Cursos de Formação Continuada em Educação Especial",
        "descricao": "01 (uma) cópia FRENTE e VERSO de certificado(s) de cursos de formação continuada em Educação Especial, com carga horária de, no mínimo, 40 (quarenta) horas que totalizem o somatório de 360 (trezentos e sessenta) horas (somente para Auxiliar de Ensino de Educação Especial);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "8",
        "tipo": "1",
        "titulo": "Certificados de Cursos de Formação Continuada na Modalidade Específica",
        "descricao": "01 (uma) cópia  FRENTE e VERSO de certificado(s) de cursos de formação continuada na modalidade específica, com no mínimo 60 (sessenta) horas (somente para Educação Física - Atividades Complementares na Educação Básica: dança, capoeira, jogos cooperativos, xadrez, modalidades esportivas individuais e coletivas) ou declaração de, no mínimo, 10 (dez) meses de experiência na modalidade específica (somente para Educação Física - Atividades Complementares na Educação Básica: dança, capoeira, jogos cooperativos, xadrez, modalidades esportivas individuais e coletivas);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "9",
        "tipo": "1",
        "titulo": "Certificados de Cursos de Formação Continuada em Braille",
        "descricao": "01 (uma) cópia  FRENTE e VERSO de certificado(s) de cursos de formação continuada em Braille, com no mínimo 60 (sessenta) horas (somente para Auxiliar de Ensino-Revisor de Braille);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "23",
        "tipo": "1",
        "titulo": "Certificados de Cursos de Formação Continuada Intérprete Educacional em LIBRAS",
        "descricao": "01 (uma) cópia  FRENTE e VERSO de Certificado(s) de Cursos de Formação Continuada em LIBRAS, com no mínimo, 360 (trezentos e sessenta) horas (somente para Intérprete Educacional);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "24",
        "tipo": "1",
        "titulo": "Certificados de Cursos de Formação Continuada para Professor em LIBRAS",
        "descricao": "01 (uma) cópia  FRENTE e VERSO de Certificado(s) de Cursos de Formação Continuada em LIBRAS, com no mínimo, 360 (trezentos e sessenta) horas (somente para Professor em LIBRAS);",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "22",
        "tipo": "1",
        "titulo": "Declaração de Vínculo Empregatício",
        "descricao": "01 (uma) cópia da declaração de vínculo empregatício, devidamente preenchida e assinada;",
        "grupo": "certidao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "12",
        "tipo": "1",
        "titulo": "Certidão de Casamento",
        "descricao": "01 (uma) cópia da Certidão de Casamento (se casado for);",
        "grupo": "certidao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "13",
        "tipo": "1",
        "titulo": "Certidão de Nascimento e CPF dos Filhos",
        "descricao": "01 (uma) cópia da Certidão de Nascimento e CPF de Filhos até a idade de 18 (dezoito) anos;",
        "grupo": "certidao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "20",
        "tipo": "1",
        "titulo": "Certidão Criminal de 1º Grau de Jurisdição",
        "descricao": "01 (uma) cópia da Certidão Criminal de 1º Grau de Jurisdição, impressão através do link: https://esaj.tjsc.jus.br/sco/abrirCadastro.do (ou no fórum);",
        "grupo": "certidao",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "6",
        "tipo": "1",
        "titulo": "Histórico Escolar",
        "descricao": "01 (uma) cópia do histórico escolar que comprove a habilitação (quando solicitado pelo Setor de Pessoal da Secretaria de Educação);",
        "grupo": "outros",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "15",
        "tipo": "1",
        "titulo": "Fotografia 3x4",
        "descricao": "01 (uma) fotografia 3x4 recente;",
        "grupo": "outros",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "18",
        "tipo": "1",
        "titulo": "Conta-salário junto a Caixa Econômica Federal",
        "descricao": "01 (uma) cópia dos dados bancários da conta-salário junto à Caixa Econômica Federal: nº da Agência, nº da Conta-salário e (Operação 037 ou Operação 3700), vinculada ao CNPJ da Prefeitura Municipal de São José. Caso não possua essa conta-salário, deverá encaminhar cópia do cartão do banco que  possui conta (dados bancários), para posterior vinculação da conta-salário que será aberta na Caixa;",
        "grupo": "outros",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "19",
        "tipo": "1",
        "titulo": "Atestado de Saúde Ocupacional - Admissional (ASO)",
        "descricao": "01 (uma) cópia do Atestado de Saúde Ocupacional - Admissional (ASO), mencionando que apresenta condições físicas e mentais para o exercício do cargo proposto, sem restrições e apto ao trabalho presencial;",
        "grupo": "outros",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "25",
        "tipo": "1",
        "titulo": "Ficha Cadastral",
        "descricao": "Anexo automático da ficha cadastral após confirmação dos dados inseridos;",
        "grupo": "outros",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "26",
        "tipo": "1",
        "titulo": "Registro Profissional CREF3/SC",
        "descricao": "01 (uma) cópia FRENTE e VERSO do Registro Profissional CREF3/SC Ou Fotocopia do Protocolo de solicitação do registro\nLembrete: após 30 dias da entrega da documentação, entregar Fotocopia da carteira profissional do CREF3/SC;",
        "grupo": "certificado",
        "multiplo": "1",
        "verso": "1"
    },
    {
        "id": "27",
        "tipo": "1",
        "titulo": "Comprovante de Qualificação Cadastral E-Social",
        "descricao": "01 (uma) cópia do Comprovante de Qualificação Cadastral E-Social, com data de emissão até 30 (trinta) dias, através do link: https://consultacadastral.inss.gov.br/Esocial/pages/index.xhtml;",
        "grupo": "comprovante",
        "multiplo": "1",
        "verso": "1"
    }
];